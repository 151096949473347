import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faBell, faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/customerColumns";
import {
  faCaretDown,
  faCirclePlus,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import NewCustomer from "../Modals/NewCustomer";
import { selectUser } from "../../redux/slices/userSlice";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
function Customers() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["Admin"].includes(adminRole);

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  const [showModal, setShowModal] = React.useState(false);

  const [customers, setCustomers] = useState([]);
  var data = [];
  const fetchCustomers = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/users/allcustomers`
    );
    const data = response.data;
    if (data.length !== customers.length) {
      setCustomers(data);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [customers]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(customers);
  }, [customers]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        customers.filter((customer) => {
          return customer.customer.email.includes(searchInput);
        })
      );
    } else {
      setFilteredData(customers);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const customer = filteredData[i];
    data = [
      ...data,
      {
        gender: customer.gender ? "Male" : "Female",
        name: customer.customer.first_name + customer.customer.last,
        email: customer.customer.email,
        phone: customer.customer.phone_number,
        points: customer.points,
        level: customer.level,
        city: customer.city,
        registred: customer.customer.createdAt.slice(0, 10),
        lastSeen: customer.customer.updatedAt.slice(0, 10),
        view: (
          <Link
            to={
              !disabled
                ? `/customer?id=${customer.customer.id}`
                : "/users?tab=2"
            }
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
      },
    ];
  }

  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewCustomer
          customers={customers}
          setCustomers={setCustomers}
          setShowModal={setShowModal}
        />
      ) : null}
      {/* <div
        onClick={() => !disabled && setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new customer </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div> */}
    </div>
  );
}

export default Customers;

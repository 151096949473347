import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "../components/SideBar";
import { useLocation } from "react-router-dom";
import UpperTotal from "../components/UpperTotal";
import {
  faCircle,
  faArrowDown,
  faArrowUp,
  faUser,
  faCaretDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import NetworkError from "../components/Modals/NetworkError";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
function CouponDetails() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({});

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let couponId = queryParams.get("id");
  const [coupon, setCoupon] = useState({});

  const fetchCoupon = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/coupons/${couponId}`
    );
    setValues({
      couponCode: response.data.coupon_code,
      value: response.data.value,
      type: response.data.type_coupon,
      appliesTo: "",
      expirationDate: response.data.expiry_date.slice(0, 10),
    });
    setCoupon(response.data);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  const generateCode = () => {
    var length = 10,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setValues({ ...values, couponCode: "IQ-" + retVal });
  };

  const updateCoupon = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${"https://apisedi.sedihisham.com"}/coupons/update/${couponId}`, {
        coupon_code: values.couponCode,
        type: values.type,
        value: parseInt(values.value),
        applies_to_arr_products: values.appliesTo,
        expiry_date: values.expirationDate,
      })
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  //  MULTI SELECTOR

  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/products/allProducts`
    );
    const data = response.data;
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  var optionsArray = [];
  const [options, setOptions] = useState([]);
  useEffect(() => {
    products?.map((product) => {
      optionsArray = [
        ...optionsArray,
        {
          value: product.id,
          label: product.product_translations[1].nameProduct,
        },
      ];
    });
    setOptions(optionsArray);
  }, [products]);

  const [preSelected, setPreSelected] = useState([]);
  var selectDefault = [];
  var selectProdIds = [];
  useEffect(() => {
    if (coupon) {
      coupon.productAffected?.map((product) => {
        selectDefault = [
          ...selectDefault,
          {
            value: product.id,
            label: product.product_translations[1].nameProduct,
          },
        ];
        selectProdIds = [...selectProdIds, product.id];
      });
      setPreSelected(selectDefault);
      setValues({ ...values, appliesTo: selectProdIds });
    }
  }, [coupon]);

  const multiSelectOnChange = (selectedOptions) => {
    var selected = [];
    selectedOptions.map((option) => {
      selected = [...selected, option.value];
    });
    setValues({ ...values, appliesTo: selected });
  };

  return (
    <form onSubmit={updateCoupon}>
      <div className="w-[96%] text-[17px] m-auto mt-6">
        <div className="m-auto mt-6 flex">
          <div>
            <SideBar />
          </div>
          <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
            <div className="flex justify-between items-center w-full">
              <Breadcrumbs
                breads={[
                  {
                    title: "Coupons",
                    link: "/offers?tab=2",
                  },
                  {
                    title: `Coupon Details (${couponId})`,
                    link: `/coupon-details?id=${couponId}`,
                  },
                ]}
              />
              <div className="flex items-center h-8 w-[30%]">
                <UserIcon />
              </div>
            </div>
            <div className="bg-[white] grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
              <div className=" py-12 px-28">
                <div className="flex">
                  <div className="w-[20%] text-[14px] mr-4 font-[500]">
                    <label htmlFor="coupon-code">
                      Coupon Code <span className="text-[red]">*</span>
                    </label>
                  </div>
                  <input
                    required
                    className="w-[60%] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter or automatically generate a coupon code"
                    onChange={changeHandler}
                    value={values.couponCode}
                    name="couponCode"
                    type="text"
                    id="coupon-code"
                  />
                  <div
                    onClick={generateCode}
                    className="text-[14px] cursor-pointer justify-center flex items-center px-2 rounded-md w-[140px] text-center font-[400] h-[35px] text-[#fff]  bg-[#0388CC]"
                  >
                    Auto Generate
                  </div>
                </div>
                {/* {} */}
                <div className="flex mt-6">
                  <div className="w-[20%] text-[14px] mr-4 font-[500]">
                    <label htmlFor="type">
                      Type <span className="text-[red]">*</span>
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      values.type === "Percentage"
                        ? setValues({ ...values, type: "Fixed" })
                        : setValues({ ...values, type: "Percentage" });
                    }}
                    className="w-[30%] cursor-pointer font-[400] flex items-center px-3 justify-between text-[14px] h-[35px] mr-2 rounded border-[#0388CC] border-[1px]"
                  >
                    <div>{values.type}</div>{" "}
                    <div>
                      <FontAwesomeIcon icon={faSort} />
                    </div>
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="w-[20%] text-[14px] mr-4 font-[500]">
                    <label htmlFor="value">
                      Value {values.type === "Percentage" && <span>(%)</span>}
                      <span className="text-[red]">*</span>
                    </label>
                  </div>
                  <input
                    required
                    className="w-[30%] pr-[115px] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] text-[14px] placeholder:font-[400] font-[400]"
                    placeholder="Enter coupon value"
                    onChange={changeHandler}
                    value={values.value}
                    name="value"
                    type="number"
                    id="value"
                  />
                </div>
                {/* <div className="flex mt-6">
                  <div className="w-[20%] text-[14px] mr-4 font-[500]">
                    <label htmlFor="applies-to">
                      Applies To <span className="text-[red]">*</span>
                    </label>
                  </div>
                  <div className="w-[500px]">
                    {preSelected.length !== 0 ? (
                      <Select
                        isMulti
                        options={options}
                        defaultValue={preSelected?.map((ele) => ele)}
                        onChange={multiSelectOnChange}
                      />
                    ) : null}
                    {preSelected.length === 0 ? (
                      <Select
                        isMulti
                        options={options}
                        onChange={multiSelectOnChange}
                      />
                    ) : null}
                  </div>
                </div> */}
                <div className="flex mt-6">
                  <div className="w-[20%] text-[14px] mr-4 font-[500]">
                    <label htmlFor="expiation-date">
                      Expiration Date <span className="text-[red]">*</span>
                    </label>
                  </div>
                  <input
                    required
                    className="w-[20%] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] text-[12px] font-[400] placeholder:font-[400]"
                    type="date"
                    onChange={changeHandler}
                    value={values.expirationDate}
                    name="expirationDate"
                    id="expiation-date"
                  />
                </div>
              </div>
              {showError ? <NetworkError setShowModal={setShowError} /> : null}
              {loading ? <Loading /> : null}
              <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
                <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
                  Cancel
                </div>
                <input
                  type="submit"
                  className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                  value="Save"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mb-[10px] flex justify-center text-[14px] text-[#707070] bg-[#fff] h-7 rounded-xl mt-2 items-center font-normal shadow-[0px_0px_16px_rgb(210,215,211)]">
          Copyright © 2023 . Powered by BIGBANG Information Technology
          Solutions.
        </div>
      </div>
    </form>
  );
}

export default CouponDetails;

import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../components/Axios/base_uri';
import toast from 'react-hot-toast';

const EditJobForm = ({
  jobData,
  onSubmit,
  onCancel,
  language,
  setLoading,
  setEditOpen,
  getJobs,
}) => {
  const initialFormData = {
    job_name_ar: '',
    job_name_en: '',
    cv_required: false,
    location: '',
    questions: [],
    multiple_choices: [],
    multiple_question_multi: [],
  };

  const [editData, setEditData] = useState(initialFormData);

  useEffect(() => {
    if (jobData) {
      setEditData({
        job_name_ar: jobData.name_ar,
        job_name_en: jobData.name_en,
        cv_required: jobData.cvRequired,
        location: jobData.location,
        questions: jobData.simple_questions.map((question) => question.name),
        multiple_choices: jobData.multi_choices_questions.map(
          (choiceQuestion) => ({
            question: choiceQuestion.question_name,
            choices: choiceQuestion.answers.map((answer) => answer.name_answer),
          })
        ),
        multiple_question_multi: jobData.multi_choice_with_field.map(
          (multiQuestion) => ({
            question: multiQuestion.name,
            fields: multiQuestion.answers_with_field.map((field) => ({
              text: field.name,
              choices: field.answers.map((answer) => answer.name_answer),
            })),
          })
        ),
      });
    }
  }, [jobData]);

  const handleEditJobChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setEditData((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleEditQuestionChange = (e, index) => {
    const { value } = e.target;
    const updatedQuestions = [...editData.questions];
    updatedQuestions[index] = value;
    setEditData((prevState) => ({
      ...prevState,
      questions: updatedQuestions,
    }));
  };

  const handleEditMultipleChoiceQuestionChange = (e, index) => {
    const { value } = e.target;
    const updatedMultipleChoices = [...editData.multiple_choices];
    updatedMultipleChoices[index] = {
      ...updatedMultipleChoices[index],
      question: value,
    };
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: updatedMultipleChoices,
    }));
  };

  const handleEditChoiceChange = (e, index, choiceIndex) => {
    const { value } = e.target;
    const updatedChoices = [...editData.multiple_choices[index].choices];
    updatedChoices[choiceIndex] = value;
    const updatedMultipleChoices = [...editData.multiple_choices];
    updatedMultipleChoices[index] = {
      ...updatedMultipleChoices[index],
      choices: updatedChoices,
    };
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: updatedMultipleChoices,
    }));
  };

  const handleEditMultipleQuestionMultiChange = (e, index) => {
    const { value } = e.target;
    const updatedMultipleQuestionMulti = [...editData.multiple_question_multi];
    updatedMultipleQuestionMulti[index] = {
      ...updatedMultipleQuestionMulti[index],
      question: value,
    };
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: updatedMultipleQuestionMulti,
    }));
  };

  const handleEditFieldChange = (e, index, fieldIndex) => {
    const { value } = e.target;
    const updatedFields = [...editData.multiple_question_multi[index].fields];
    updatedFields[fieldIndex] = {
      ...updatedFields[fieldIndex],
      text: value,
    };
    const updatedMultipleQuestionMulti = [...editData.multiple_question_multi];
    updatedMultipleQuestionMulti[index] = {
      ...updatedMultipleQuestionMulti[index],
      fields: updatedFields,
    };
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: updatedMultipleQuestionMulti,
    }));
  };

  const handleEditFieldChoiceChange = (e, index, fieldIndex, choiceIndex) => {
    const { value } = e.target;
    const updatedChoices = [
      ...editData.multiple_question_multi[index].fields[fieldIndex].choices,
    ];
    updatedChoices[choiceIndex] = value;
    const updatedFields = [...editData.multiple_question_multi[index].fields];
    updatedFields[fieldIndex] = {
      ...updatedFields[fieldIndex],
      choices: updatedChoices,
    };
    const updatedMultipleQuestionMulti = [...editData.multiple_question_multi];
    updatedMultipleQuestionMulti[index] = {
      ...updatedMultipleQuestionMulti[index],
      fields: updatedFields,
    };
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: updatedMultipleQuestionMulti,
    }));
  };

  const addEditQuestion = () => {
    setEditData((prevState) => ({
      ...prevState,
      questions: [...prevState.questions, ''],
    }));
  };

  const addEditChoice = (index) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: prevState.multiple_choices.map((item, idx) =>
        idx === index
          ? {
              ...item,
              choices: [...item.choices, ''],
            }
          : item
      ),
    }));
  };

  const addEditField = (index) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: prevState.multiple_question_multi.map(
        (item, idx) =>
          idx === index
            ? {
                ...item,
                fields: [...item.fields, { text: '', choices: [''] }],
              }
            : item
      ),
    }));
  };

  const addEditFieldChoice = (index, fieldIndex) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: prevState.multiple_question_multi.map(
        (item, idx) =>
          idx === index
            ? {
                ...item,
                fields: item.fields.map((field, fIdx) =>
                  fIdx === fieldIndex
                    ? {
                        ...field,
                        choices: [...field.choices, ''],
                      }
                    : field
                ),
              }
            : item
      ),
    }));
  };

  const addEditMultipleChoiceQuestion = () => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: [
        ...prevState.multiple_choices,
        { question: '', choices: [''] },
      ],
    }));
  };

  const addEditMultipleQuestionMulti = () => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: [
        ...prevState.multiple_question_multi,
        { question: '', fields: [{ text: '', choices: [''] }] },
      ],
    }));
  };

  const removeEditMultipleChoiceQuestion = (index) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: prevState.multiple_choices.filter(
        (item, idx) => idx !== index
      ),
    }));
  };

  const removeEditField = (index, fieldIndex) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: prevState.multiple_question_multi.map(
        (item, idx) =>
          idx === index
            ? {
                ...item,
                fields: item.fields.filter(
                  (field, fIdx) => fIdx !== fieldIndex
                ),
              }
            : item
      ),
    }));
  };

  const removeEditQuestion = (index) => {
    setEditData((prevState) => ({
      ...prevState,
      questions: prevState.questions.filter((_, idx) => idx !== index),
    }));
  };
  
  const removeEditChoice = (index, choiceIndex) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_choices: prevState.multiple_choices.map((item, idx) =>
        idx === index
          ? {
              ...item,
              choices: item.choices.filter((_, cIdx) => cIdx !== choiceIndex),
            }
          : item
      ),
    }));
  };
  
  const removeEditFieldChoice = (index, fieldIndex, choiceIndex) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: prevState.multiple_question_multi.map((item, idx) =>
        idx === index
          ? {
              ...item,
              fields: item.fields.map((field, fIdx) =>
                fIdx === fieldIndex
                  ? {
                      ...field,
                      choices: field.choices.filter((_, cIdx) => cIdx !== choiceIndex),
                    }
                  : field
              ),
            }
          : item
      ),
    }));
  };
  

  const removeEditMultipleQuestionMulti = (index) => {
    setEditData((prevState) => ({
      ...prevState,
      multiple_question_multi: prevState.multiple_question_multi.filter(
        (item, idx) => idx !== index
      ),
    }));
  };

  const handleSubmitEditJob = async () => {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        job_name_ar: editData.job_name_ar,
        job_name_en: editData.job_name_en,
        cv_required: editData.cv_required,
        location: editData.location,
        questions: editData.questions.map((question, index) => ({
          text: question,
          id: jobData.simple_questions[index]?.id, // Handle existing data
        })),
        multiple_choices: editData.multiple_choices.map(
          (multipleChoice, index) => ({
            question: multipleChoice.question,
            id: jobData.multi_choices_questions[index]?.id, // Handle existing data
            choices: multipleChoice.choices.map((choice, choiceIndex) => ({
              text: choice,
              id: jobData.multi_choices_questions[index]?.answers[choiceIndex]
                ?.id, // Handle existing data
            })),
          })
        ),
        multiple_question_multi: editData.multiple_question_multi.map(
          (multipleQuestionMulti, index) => ({
            question: multipleQuestionMulti.question,
            id: jobData.multi_choice_with_field[index]?.id, // Handle existing data
            fields: multipleQuestionMulti.fields.map((field, fieldIndex) => ({
              text: field.text,
              id: jobData.multi_choice_with_field[index]?.answers_with_field[
                fieldIndex
              ]?.id, // Handle existing data
              choices: field.choices.map((choice, choiceIndex) => ({
                text: choice,
                id: jobData.multi_choice_with_field[index]?.answers_with_field[
                  fieldIndex
                ]?.answers[choiceIndex]?.id, // Handle existing data
              })),
            })),
          })
        ),
      }),
    };

    try {
      const response = await fetch(
        `${BASE_URL}careers/jobmanagement/${jobData.id}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error('Failed to update job');
      }
      const responseData = await response.json();
      toast.success('Job updated successfully');
      getJobs();
      setEditOpen(false);
    } catch (error) {
      toast.error('Failed to update job');
    }
  };

  return (
    <div className="popup-container-xyz987">
      <div className="popup-abc123">
        <h2>{language === 'ar' ? 'تعديل الوظيفة' : 'Edit Job'}</h2>

        <div className="form-group-xyz321">
          <label htmlFor="job_name_ar">
            {language === 'ar' ? 'اسم الوظيفة (عربي):' : 'Job Name (Arabic):'}
          </label>
          <input
            type="text"
            id="job_name_ar"
            name="job_name_ar"
            value={editData.job_name_ar}
            onChange={handleEditJobChange}
          />
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="job_name_en">
            {language === 'ar'
              ? 'اسم الوظيفة (إنجليزي):'
              : 'Job Name (English):'}
          </label>
          <input
            type="text"
            id="job_name_en"
            name="job_name_en"
            value={editData.job_name_en}
            onChange={handleEditJobChange}
          />
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="cv_required">
            {language === 'ar' ? 'مطلوب سيرة ذاتية:' : 'CV Required:'}
          </label>
          <input
            type="checkbox"
            id="cv_required"
            name="cv_required"
            checked={editData.cv_required}
            onChange={handleEditJobChange}
          />
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="location">
            {language === 'ar' ? 'الموقع:' : 'Location:'}
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={editData.location}
            onChange={handleEditJobChange}
          />
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="questions">
            {language === 'ar' ? 'الأسئلة:' : 'Questions:'}
          </label>
          {editData.questions.map((question, index) => (
            <div key={index} className="choice-field-rst890">
              <label htmlFor={`question_${index}`}>
                {`${language === 'ar' ? 'سؤال' : 'Question'} ${index + 1}:`}
              </label>
              <input
                type="text"
                id={`question_${index}`}
                name="question"
                value={question}
                onChange={(e) => handleEditQuestionChange(e, index)}
              />
              <button
                className="btn btn-danger"
                onClick={() => removeEditQuestion(index)}
              >
                {language === 'ar' ? 'حذف السؤال' : 'Remove Question'}
              </button>
            </div>
          ))}
          <button className="btn btn-primary" onClick={addEditQuestion}>
            {language === 'ar' ? 'إضافة سؤال' : 'Add Question'}
          </button>
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="multiple_choices">
            {language === 'ar'
              ? 'أسئلة متعددة الاختيارات:'
              : 'Multiple Choice Questions:'}
          </label>
          {editData.multiple_choices.map((multipleChoice, index) => (
            <div key={index} className="choices-container-pqr789">
              <label htmlFor={`multiple_choice_question_${index}`}>
                {`${
                  language === 'ar'
                    ? 'سؤال متعدد الاختيارات'
                    : 'Multiple Choice Question'
                } ${index + 1}:`}
              </label>
              <input
                type="text"
                id={`multiple_choice_question_${index}`}
                name="multiple_choice"
                value={multipleChoice.question}
                onChange={(e) =>
                  handleEditMultipleChoiceQuestionChange(e, index)
                }
              />
              <div className="choice-fields-xyz567">
                {multipleChoice.choices.map((choice, choiceIndex) => (
                  <div key={choiceIndex} className="choice-field-rst890">
                    <label htmlFor={`choice_${index}_${choiceIndex}`}>
                      {`${language === 'ar' ? 'الاختيار' : 'Choice'} ${
                        choiceIndex + 1
                      }:`}
                    </label>
                    <input
                      type="text"
                      id={`choice_${index}_${choiceIndex}`}
                      name="choice"
                      value={choice}
                      onChange={(e) =>
                        handleEditChoiceChange(e, index, choiceIndex)
                      }
                    />
                    <button
                      className="btn btn-danger"
                      onClick={() => removeEditChoice(index, choiceIndex)}
                    >
                      {language === 'ar' ? 'حذف الاختيار' : 'Remove Choice'}
                    </button>
                  </div>
                ))}
              </div>
              <button
                className="btn btn-primary"
                onClick={() => addEditChoice(index)}
              >
                {language === 'ar' ? 'إضافة اختيار' : 'Add Choice'}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => removeEditMultipleChoiceQuestion(index)}
              >
                {language === 'ar' ? 'حذف السؤال' : 'Remove Question'}
              </button>
            </div>
          ))}
          <button
            className="btn btn-primary"
            onClick={addEditMultipleChoiceQuestion}
          >
            {language === 'ar'
              ? 'إضافة سؤال متعدد الاختيارات'
              : 'Add Multiple Choice Question'}
          </button>
        </div>

        <div className="form-group-xyz321">
          <label htmlFor="multiple_question_multi">
            {language === 'ar'
              ? 'أسئلة متعددة الاختيارات مع حقول إجابة:'
              : 'Multiple Choice Questions with Answer Fields:'}
          </label>
          {editData.multiple_question_multi.map(
            (multipleQuestionMulti, index) => (
              <div key={index} className="multiple-choice-card">
                <label htmlFor={`multiple_question_multi_${index}`}>
                  {`${
                    language === 'ar'
                      ? 'سؤال متعدد الاختيارات مع حقول إجابة'
                      : 'Multiple Choice Question with Answer Fields'
                  } ${index + 1}:`}
                </label>
                <input
                  type="text"
                  id={`multiple_question_multi_${index}`}
                  name="multiple_question_multi"
                  value={multipleQuestionMulti.question}
                  onChange={(e) =>
                    handleEditMultipleQuestionMultiChange(e, index)
                  }
                />
                <div className="fields-container">
                  {multipleQuestionMulti.fields.map((field, fieldIndex) => (
                    <div key={fieldIndex} className="field">
                      <label htmlFor={`field_${index}_${fieldIndex}`}>
                        {`${language === 'ar' ? 'الحقل' : 'Field'} ${
                          fieldIndex + 1
                        }:`}
                      </label>
                      <input
                        type="text"
                        id={`field_${index}_${fieldIndex}`}
                        name="field"
                        value={field.text}
                        onChange={(e) =>
                          handleEditFieldChange(e, index, fieldIndex)
                        }
                      />

                      <div className="choices-container">
                        {field.choices.map((choice, choiceIndex) => (
                          <div key={choiceIndex} className="choice">
                            <label
                              htmlFor={`field_choice_${index}_${fieldIndex}_${choiceIndex}`}
                            >
                              {`${language === 'ar' ? 'الاختيار' : 'Choice'} ${
                                choiceIndex + 1
                              }:`}
                            </label>
                            <input
                              type="text"
                              id={`field_choice_${index}_${fieldIndex}_${choiceIndex}`}
                              name="field_choice"
                              value={choice}
                              onChange={(e) =>
                                handleEditFieldChoiceChange(
                                  e,
                                  index,
                                  fieldIndex,
                                  choiceIndex
                                )
                              }
                            />
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                removeEditFieldChoice(
                                  index,
                                  fieldIndex,
                                  choiceIndex
                                )
                              }
                            >
                              {language === 'ar'
                                ? 'حذف الاختيار'
                                : 'Remove Choice'}
                            </button>
                          </div>
                        ))}
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => addEditFieldChoice(index, fieldIndex)}
                      >
                        {language === 'ar' ? 'إضافة اختيار' : 'Add Choice'}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => removeEditField(index, fieldIndex)}
                      >
                        {language === 'ar' ? 'حذف' : 'Remove Field'}
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => addEditField(index)}
                >
                  {language === 'ar' ? 'إضافة حقل' : 'Add Field'}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => removeEditMultipleQuestionMulti(index)}
                >
                  {language === 'ar' ? 'حذف السؤال' : 'Remove Question'}
                </button>
              </div>
            )
          )}
          <button
            className="btn btn-primary"
            onClick={addEditMultipleQuestionMulti}
          >
            {language === 'ar'
              ? 'إضافة سؤال متعدد الاختيارات مع حقول إجابة'
              : 'Add Multiple Choice Question with Answer Fields'}
          </button>
        </div>

        <div className="button-container-stu456">
          <button className="btn btn-success" onClick={handleSubmitEditJob}>
            {language === 'ar' ? 'حفظ التعديلات' : 'Save Changes'}
          </button>
          <button className="btn btn-secondary" onClick={onCancel}>
            {language === 'ar' ? 'إلغاء' : 'Cancel'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditJobForm;

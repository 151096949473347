import React from "react";
import SideBar from "../components/SideBar";
import {
  faUser,
  faCaretDown,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faComment,
  faFilePdf,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import _ from "lodash";
import Footer from "../components/Footer";
function JobApplicationInfo() {
  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let applicationId = queryParams.get("id");

  const [simpleQuestions, setSimpleQuestions] = useState([]);
  const [mediumQuestions, setMediumQuestions] = useState([]);
  const [hardQuestions, setHardQuestions] = useState({});

  const [application, setApplication] = useState({});

  const fetchApplication = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/careers/jobapplication/${applicationId}`
    );
    setApplication(response.data);
    var simple = [];
    var medium = [];
    var hard = [];
    response.data.qst_answers.map((answer) => {
      if (answer.type_Qst === "Simple") {
        simple = [...simple, answer];
      } else if (answer.type_Qst === "Medium") {
        medium = [...medium, answer];
      } else if (answer.type_Qst === "Hard") {
        hard = [...hard, answer];
      }
    });
    setSimpleQuestions([...simple]);
    setMediumQuestions([...medium]);
    // const grouped = _.mapValues(_.groupBy(response.data, "parent_qst"), (clist) =>
    //   clist.map((q) => _.omit(q, "parent_qst"))
    // );
    const grouped = _.groupBy(hard, (q) => q.parent_qst);
    setHardQuestions(grouped);
  };
  const [certs, setCerts] = useState([]);
  const fetchCertificates = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/careers/certificats/${applicationId}`
    );
    setCerts(response.data);
  };

  useEffect(() => {
    fetchApplication();
    fetchCertificates();
  }, []);

  return (
    <div className="w-[96%]  text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Careers",
                  link: "/careers?tab=1",
                },
                {
                  title: `Job Application Details (${applicationId})`,
                  link: `/job-application-info?id=${applicationId}`,
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] font-arabic h-[694px] text-right grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className="scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div>
                <div className="w-[1040px] float-right mr-[12%] mt-10">
                  <p className="text-[#033362] mr-[20px] font-extrabold text-[22px]">
                    معلومات أساسية
                  </p>
                  <div className="flex flex-wrap">
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        رقم الهاتف
                      </p>
                      <div className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] ">
                        {application.phone_number}
                      </div>
                    </div>
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الإسم
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.full_name}
                      </div>
                    </div>
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        رقم الجوال
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.phone_number}
                      </div>
                    </div>
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        البريد الإلكتروني
                      </p>
                      <div
                        dir="ltr"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.email}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        مكان وتاريخ الولادة
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.birth_place} -{" "}
                        {application.birdthday?.slice(0, 10)}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        رقم البطاقة الشخصية
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.national_id}
                      </div>
                    </div>
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الإختصاص
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.job_management?.name_ar}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        السيرة الذاتية
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center pl-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        <a
                          href={
                            "https://apisedi.sedihisham.com" +
                            "/" +
                            application.cv_url
                          }
                          target="_blank"
                        >
                          <div className="h-[30px] ml-[3px] ml-2 rounded w-[35px] leading- text-[14px] text-center text-[#fff] bg-[#F40F02] flex items-center justify-center cursor-pointer">
                            <FontAwesomeIcon icon={faFilePdf} />
                          </div>
                        </a>
                        {application.full_name + "-resume.pdf"}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الجنسية
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.nationalty}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الحالة الإجتماعية
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.family_status}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        العمر
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {new Date().getFullYear() -
                          application.birdthday?.slice(0, 4)}
                      </div>
                    </div>
                    <div className="w-[500px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        العنوان
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.address}
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الخدمة الالزامية
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        xxxx
                      </div>
                    </div>
                    <div className="w-[240px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        الجنس
                      </p>
                      <div
                        dir="rtl"
                        className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                      >
                        {application.sex}
                      </div>
                    </div>
                    <div className="w-[1020px] mr-[20px] my-[10px]">
                      <p className="text-[15px] text-[#0388CC] font-[500]">
                        ملاحظات
                      </p>
                      <div className="w-full h-[80px] text-[14px] text-[#000] p-2 border-[1px] rounded border-[#c4c8cf] ">
                        {application.notes}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-[1040px] float-right mr-[12%] mt-10">
                  <p className="text-[#033362] mr-[20px] font-extrabold text-[22px]">
                    الأسئلة
                  </p>
                  <div className="flex mb-[20px] flex-wrap">
                    {/* {"CHAHADAT"} */}
                    {certs.map((cert, i) => (
                      <div className="flex flex-wrap">
                        <div className="w-[180px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            سنة التخرج
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {cert.year_of_graduation.slice(0,10)}
                          </div>
                        </div>
                        <div className="w-[180px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            مصدر الشهادة
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {cert.source_certificat}
                          </div>
                        </div>
                        <div className="w-[300px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            التخصص
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {cert.field}
                          </div>
                        </div>
                        <div className="w-[300px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            {certs.length>1 ? i+1 : null} الشهادة
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {cert.name}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {"Simplze and MEDIUM QUESTIONS"} */}
                    <div>
                      {simpleQuestions.map((q) => (
                        <div className="w-[1020px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            {q.qst}
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {q.answer}
                          </div>
                        </div>
                      ))}
                      {mediumQuestions.map((q) => (
                        <div className="w-[1020px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            {q.qst}
                          </p>
                          <div
                            dir="rtl"
                            className="w-full h-[30px] text-[14px] text-[#000] flex items-center px-2 border-[1px] rounded border-[#c4c8cf] "
                          >
                            {q.answer}
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* {"HARD QUESTIONS"} */}
                    <div>
                      {Object.keys(hardQuestions).map((f) => (
                        <div className="w-[1020px] mr-[20px] my-[10px]">
                          <p className="text-[15px] text-[#0388CC] font-[500]">
                            {f}
                          </p>
                          <div className=" justify-between flex-wrap">
                            {hardQuestions[f].map((q) => (
                              <div className="h-[30px] mb-4  w-full  border-[1px]  flex items-center rounded border-[#c4c8cf] ">
                                <span className="pr-4 float-left  text-[#000]  font-[700] px-2 text-[14px] ">
                                  {q.qst}:
                                </span>{" "}
                                <span className="text-[200] px-2 text-[14px]">
                                  {q.answer}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobApplicationInfo;

import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import SettingsTab from "../SettingsTab";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/settingsTabs.module.css";
import FileItem from "../FileUploader/FileItem";
import FileMultiUpload from "../FileUploader/FileMultiUpload";
import axios from "axios";
import { selectCooksCategory } from "../../redux/slices/itemSlice";
import Select from "react-select";
import Loading from "../Loading";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function NewCook({ setShowModal, selectedCooksCategory, cooks, setCooks }) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // Fetching products
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/products/allProducts`
    );
    const data = response.data;
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  var optionsArray = [];
  const [options, setOptions] = useState([]);
  useEffect(() => {
    products.map((category) => {
      optionsArray = [
        ...optionsArray,
        {
          value: category.id,
          label: category.product_translations[1].nameProduct,
        },
      ];
    });
    setOptions(optionsArray);
  }, [products]);

  const multiSelectOnChange = (selectedOptions) => {
    var selected = [];
    selectedOptions.map((option) => {
      selected = [...selected, option.value];
    });
    setValues({ ...values, recommandedProducts: selected });
  };

  //Cook Image
  const [cookImage, setCookImage] = useState([]);
  const [cookImageData, setCookImageData] = useState([]);
  const removeCookImage = (filename, index) => {
    setCookImage(null);
    setCookImageData(null);
  };

  //Cook Slider
  const [cookSlider, setCookSlider] = useState([]);
  const [cookSliderData, setCookSliderData] = useState([]);
  const removeMainFile = (filename, index) => {
    setCookSlider(cookSlider.filter((file, i) => i !== index));
    setCookSliderData(cookSliderData.filter((fileData, i) => i !== index));
  };

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [descAr, setDescAr] = useState("");
  const [descEn, setDescEn] = useState("");

  const [values, setValues] = useState({
    nameAr: "",
    nameEn: "",
    standards: "",
    ingredients_en:"",
    videoUrl: "wefwewf",
  });

  const [imageUrls, setImageUrls] = useState([]);
  const [backgroundUrl, setBackgroundUrl] = useState([]);

  var images = [];
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    await axios
      .post(
        `${"https://apisedi.sedihisham.com"}/cooks/upload/cook-image`,
        formData
      )
      .then((imgResponse) => {
        const imageUrl = imgResponse.data.imagePath;
        images = [...images, imageUrl];
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  useEffect(() => {
    if (imageUrls.length !== 0) {
      uploadCookImage();
    }
  }, [imageUrls]);

  useEffect(() => {
    if (backgroundUrl.length !== 0) {
      completeUpload();
    }
  }, [backgroundUrl]);

  const uploadCookImage = async () => {
    const formData = new FormData();
    formData.append("file", cookImage[0], cookImage[0].name);
    await axios
      .post(
        `${"https://apisedi.sedihisham.com"}/cooks/upload/cook-image`,
        formData
      )
      .then((imgResponse) => {
        setBackgroundUrl([imgResponse.data.imagePath]);
      })
      .catch((err) => {
        // inform the user
        console.log(err);
      });
  };

  const completeUpload = async () => {
    const formData = new FormData();
    formData.append("file", cookImage[0], cookImage[0].name);
    await axios
      .post(
        `${"https://apisedi.sedihisham.com"}/cooks/upload/cook-image`,
        formData
      )
      .then((imgResponse) => {
        axios
          .post(`${"https://apisedi.sedihisham.com"}/cooks/create`, {
            name_en: values.nameEn,
            name_ar: values.nameAr,
            description_en: descEn,
            description_ar: descAr,
            ingredients: values.standards,
            ingredients_en: values.ingredients_en,
            recommended_product_ids: values.recommandedProducts,
            image_background_url: backgroundUrl[0],
            id_category_cook: parseInt(selectedCooksCategory.id),
            video_url_instead_slider: values.videoInstead,
            photo_slider_urls: imageUrls,
            video_url: values.videoUrl,
          })
          .then(function (response) {
            setLoading(false);
            setError(null);
            setCooks([...cooks, response.data]);
            setShowModal(false);
            Swal.fire({
              icon: "success",
              title: "Your changes had been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((err) => {
        // inform the user
        console.log(err);
      });
  };

  const uploadImages = async () => {
    try {
      for (let i = 0; i < cookSlider.length; i++) {
        await uploadImage(cookSlider[i]);
      }
      await setImageUrls(images);
    } catch (err) {
      console.log(err);
    }
  };

  const submitCook = async (e) => {
    e.preventDefault();
    if (
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      values.standards !== "" &&
      descAr !== "" &&
      descEn !== "" &&
      cookImage.length !== 0 &&
      cookSlider.length !== 0
    ) {
      setError(null);
      setLoading(true);
      uploadImages();
    } else {
      setError("Please fill all the required fields.");
    }
  };

  return (
    <>
      <div className="justify-center mt-[-30px] h-[800px] items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitCook}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Cook
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative h-[600px] p-6 w-full scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full ">
                <div>
                  <div className="flex mt-6 flex-wrap">
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-en"
                      >
                        Name - EN
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter cook name in english"
                        onChange={changeHandler}
                        value={values.nameEn}
                        name="nameEn"
                        type="text"
                        id="name-en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-ar"
                      >
                        Name - AR
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter cook name in english"
                        onChange={changeHandler}
                        value={values.nameAr}
                        name="nameAr"
                        type="text"
                        id="name-ar"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="ingredients_en"
                      >
                        Engredients - En
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="or Copy URL for the Video Here"
                        onChange={changeHandler}
                        value={values.ingredients_en}
                        name="ingredients_en"
                        type="text"
                        id="ingredients_en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                    <label
                        className="text-[14px] font-[500]"
                        htmlFor="video-url"
                      >
                        Engredients - Ar
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter standards"
                        onChange={changeHandler}
                        value={values.standards}
                        name="standards"
                        type="text"
                        id="standards"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-[30px] w-[87%]">
                    <RichTextEditor
                      name="Description-EN"
                      initialValue=""
                      getValue={setDescEn}
                    />
                    <RichTextEditor
                      name="Description-AR"
                      initialValue=""
                      getValue={setDescAr}
                    />
                  </div>
                  <div className="flex">
                    <div className="mt-6 mr-[20px] w-[40%]">
                      <p className="text-[#000] text-[14px] font-[400]">
                        Select a images of Product ( JPEG or PNG ):
                        <span className="text-[red] font-[700]">*</span>
                      </p>
                      <div className=" w-[130px] h-[30px] mt-[5px]">
                        <FileUpload
                          required={true}
                          files={cookImage}
                          setFiles={setCookImage}
                          removeFile={removeCookImage}
                          setFileData={setCookImageData}
                        />
                      </div>
                      <FileItem
                        fileData={cookImageData}
                        file={cookImage?.[0]}
                        removeFile={removeCookImage}
                        index="0"
                      />
                    </div>
                    <div className="mt-6 w-[40%]">
                      <p className="text-[#000] text-[14px] font-[400]">
                        Recommended Products{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </p>
                      <div className="flex mt-2 text-[14px] font-[500]">
                        <div className="w-[500px]">
                          <Select
                            required={true}
                            isMulti
                            options={options}
                            onChange={multiSelectOnChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-12">
                    <h1 className="text-[#000] font-[700] text-[14px]">
                      Photo Slider
                    </h1>
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of Product ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[10px]">
                      <FileMultiUpload
                        required={true}
                        files={cookSlider}
                        filesData={cookSliderData}
                        setFilesData={setCookSliderData}
                        setFiles={setCookSlider}
                        removeFile={removeMainFile}
                      />
                    </div>
                    <div>
                      <FileList
                        files={cookSlider}
                        filesData={cookSliderData}
                        removeFile={removeMainFile}
                      />
                    </div>
                    <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
                      Video Link for the main Slider
                    </div>
                    <input
                      className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="or Copy URL for the Video Here"
                      onChange={changeHandler}
                      value={values.videoInstead}
                      name="videoInstead"
                      type="url"
                    />
                  </div>
                </div>
                {error ? (
                  <p className="text-[14px] mt-2 text-[red]">
                    <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                  </p>
                ) : null}
                {showError ? (
                  <NetworkError setShowModal={setShowError} />
                ) : null}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Loading /> : null}
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewCook;

import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";

import { faUser, faCaretDown, faShare } from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/messageColumns";
import Breadcrumbs from "../components/Breadcrumbs";
import SearchBar from "../components/SearchBar";
import UserIcon from "../components/UserIcon";
import axios from "axios";
import { Link } from "react-router-dom";
import { selectSearch } from "../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../redux/slices/itemSlice";
import Footer from "../components/Footer";
import ViewMessage from "../components/Modals/ViewMessage";
function Messages() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const [messages, setMessages] = useState([]);
  const [viewMessage , setViewMessage] = useState(false);
  const [messageData , setMessagesData] = useState({})
  const fetchMessages = async () => {
    await axios
      .get("https://apisedi.sedihisham.com/" + "/messaging/allmessages")
      .then((res) => {
        console.log(res);
        setMessages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(messages);
  }, [messages]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        messages.filter((message) => {
          return message.email.includes(searchInput);
        })
      );
    } else {
      setFilteredData(messages);
    }
  }, [searchInput]);

  var data = [];

  for (let i = 0; i < filteredData?.length; i++) {
    const message = filteredData[i];
    data = [
      ...data,
      {
        id: message.id,
        fullname: message.name,
        phone: message.phone_number,
        email: message.email,
        subject: message.sujet,
        content: message.content,
        date: message.createdAt.slice(0,10),
        reply: (
          <Link to="#">
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faShare} />
            </div>
          </Link>
        ),
        viewMessage: (
          <button onClick={() => {
            setViewMessage(true)
            setMessagesData(message)
          }} className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-2 py-[2px] text-[white] text-center font-medium">View Message</button>
        )
      },
    ];
  }

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
     {viewMessage && <ViewMessage setViewMessage={setViewMessage} data={messageData}></ViewMessage>}

      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Messages",
                  link: "/messages",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <SearchBar placeholder="Search by sender's e-mail" />
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className=" p-3 w-full">
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                showCellBorders={showCellBorders}
                showZebraRows={showZebraRows}
                style={gridStyle}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Messages;

import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileMultiUpload from "../FileUploader/FileMultiUpload";
import FileList from "../FileUploader/FileList";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from 'sweetalert2'
function HomePage() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  // UPLOADED FILES

  //Main Slider Files
  const [mainFiles, setMainFiles] = useState([]);
  const [mainFilesData, setMainFilesData] = useState([]);
  const removeMainFile = (filename, index) => {
    setMainFiles(mainFiles.filter((file, i) => i !== index));
    setMainFilesData(mainFilesData.filter((fileData, i) => i !== index));
  };

  //Catalogue File
  const [catalogue, setCatalogue] = useState([]);
  const [catalogueData, setCatalogueData] = useState([]);
  const removeCatalogue = (filename, index) => {
    setCatalogue([]);
    setCatalogueData([]);
  };

  //Second Slider
  const [secondFiles, setSecondFiles] = useState([]);
  const [secondFilesData, setSecondFilesData] = useState([]);
  const removeSecondFile = (filename, index) => {
    setSecondFiles(secondFiles.filter((file, i) => i !== index));
    setSecondFilesData(secondFilesData.filter((fileData, i) => i !== index));
  };

  //Product Gallery
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [galleryFilesData, setGalleryFilesData] = useState([]);
  const removeGalleryFile = (filename, index) => {
    setGalleryFiles(galleryFiles.filter((file, i) => i !== index));
    setGalleryFilesData(galleryFilesData.filter((fileData, i) => i !== index));
  };

  //Trade Marks
  const [marksFiles, setMarksFiles] = useState([]);
  const [marksFilesData, setMarksFilesData] = useState([]);
  const removeMarksFile = (filename, index) => {
    setMarksFiles(marksFiles.filter((file, i) => i !== index));
    setMarksFilesData(marksFilesData.filter((fileData, i) => i !== index));
  };

  //Video Links
  const [mainLink, setMainLink] = useState("");
  const [galleryLink1, setGalleryLink1] = useState("");
  const [galleryLink2, setGalleryLink2] = useState("");
  const [galleryLink3, setGalleryLink3] = useState("");
  const [galleryLink4, setGalleryLink4] = useState("");

  //NEW FILES

  const [newMainSlider, setNewMainSlider] = useState();

  const [newSecondSlider, setNewSecondSlider] = useState();

  const [newGallery, setNewGallery] = useState();

  const [newTrademarks, setNewTrademarks] = useState();

  const [newCatalogue, setNewCatalogue] = useState();

  // ///////////////////////////////////////////////////////////////////////
  // DATABASE FILES
  const [media, setMedia] = useState(null);
  const fetchMedia = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/pages/getall/home`
    );
    const data = response.data;
    if (data?.length !== media?.length) {
      setMedia(data);
    }
  };

  useEffect(() => {
    fetchMedia();
  }, []);

  const [databaseMainSlider, setDatabaseMainSlider] = useState([]);
  const removeDatabaseMainSlider = (index) => {
    setDatabaseMainSlider(databaseMainSlider.filter((file, i) => i !== index));
  };

  const [databaseSecondSlider, setDatabaseSecondSlider] = useState([]);
  const removeDatabaseSecondSlider = (index) => {
    setDatabaseSecondSlider(
      databaseSecondSlider.filter((file, i) => i !== index)
    );
  };

  const [databaseGallery, setDatabaseGallery] = useState([]);
  const removeDatabaseGallery = (index) => {
    setDatabaseGallery(databaseGallery.filter((file, i) => i !== index));
  };

  const [databaseTrademarks, setDatabaseTrademarks] = useState([]);
  const removeDatabaseTrademarks = (index) => {
    setDatabaseTrademarks(databaseTrademarks.filter((file, i) => i !== index));
  };

  const [databaseCatalogue, setDatabaseCatalogue] = useState([]);
  const removeDatabaseCatalogue = (index) => {
    setDatabaseCatalogue(databaseCatalogue.filter((file, i) => i !== index));
  };

  useEffect(() => {
    if (media !== null) {
      let mainitems = [];
      let seconditems = [];
      let gelleryitems = [];
      let trademarksitems = [];
      let linksCount = 0;
      media.map((item) => {
        if (
          item.categoryImage === "home_page_main_slider" &&
          !item.externalLink
        ) {
          mainitems = [...mainitems, item.path_image];
        } else if (item.categoryImage === "catalog") {
          setDatabaseCatalogue([item.path_image]);
        } else if (item.categoryImage === "home_page_second_slider") {
          seconditems = [...seconditems, item.path_image];
        } else if (
          item.categoryImage === "gallery_image" &&
          !item.externalLink
        ) {
          gelleryitems = [...gelleryitems, item.path_image];
        } else if (item.categoryImage === "trademarks") {
          trademarksitems = [...trademarksitems, item.path_image];
        } else if (
          item.categoryImage === "home_page_main_slider" &&
          item.externalLink
        ) {
          setMainLink(item.path_image);
        } else if (
          item.categoryImage === "gallery_image" &&
          item.externalLink
        ) {
          if (linksCount === 0) {
            setGalleryLink1(item.path_image);
            linksCount += 1;
          } else if (linksCount === 1) {
            setGalleryLink2(item.path_image);
            linksCount += 1;
          } else if (linksCount === 2) {
            setGalleryLink3(item.path_image);
            linksCount += 1;
          } else if (linksCount === 3) {
            setGalleryLink4(item.path_image);
            linksCount += 1;
          }
        }
      });
      setDatabaseMainSlider(mainitems);
      setDatabaseSecondSlider(seconditems);
      setDatabaseGallery(gelleryitems);
      setDatabaseTrademarks(trademarksitems);
    }
  }, [media]);

  // UPDATING EVERYTHING IN THE DATABASE
  const uploadImageCategory = async (
    imageCategory,
    setNewImagecategory,
    setDatabaseImagecategory,
    uploadedFiles
  ) => {
    var databaseImages = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const formData = new FormData();
      formData.append("file", uploadedFiles[i], uploadedFiles[i].name);
      await axios
        .post(
          `${"https://apisedi.sedihisham.com"}/categories/upload/category-image`,
          formData
        )
        .then((imgResponse) => {
          databaseImages = [...databaseImages, imgResponse.data.imagePath];
        })
        .catch((err) => {
          // inform the user
          console.error(err);
        });
    }
    setNewImagecategory([...imageCategory, ...databaseImages]);
    setDatabaseImagecategory([...imageCategory, ...databaseImages]);
  };
  const uploadCatalogue = async () => {
    if (catalogue.length > 0) {
      for (let i = 0; i < catalogue.length; i++) {
        const formData = new FormData();
        formData.append("file", catalogue[i], catalogue[i].name);
        await axios
          .post(
            `${"https://apisedi.sedihisham.com"}/pages/upload/home-page/pdf`,
            formData
          )
          .then((imgResponse) => {
            setNewCatalogue([imgResponse.data.imagePath]);
            setDatabaseCatalogue([imgResponse.data.imagePath]);
          })
          .catch((err) => {
            // inform the user
            console.error(err);
          });
      }
    } else {
      setNewCatalogue([...databaseCatalogue]);
    }
    setCatalogue([]);
  };

  const uploadImages = () => {
    setLoading(true);
    uploadImageCategory(
      databaseMainSlider,
      setNewMainSlider,
      setDatabaseMainSlider,
      mainFiles
    );
  };

  useEffect(() => {
    if (newMainSlider) {
      uploadCatalogue();
    }
  }, [newMainSlider]);

  useEffect(() => {
    if (newCatalogue) {
      uploadImageCategory(
        databaseSecondSlider,
        setNewSecondSlider,
        setDatabaseSecondSlider,
        secondFiles
      );
    }
  }, [newCatalogue]);

  useEffect(() => {
    if (newSecondSlider) {
      uploadImageCategory(
        databaseGallery,
        setNewGallery,
        setDatabaseGallery,
        galleryFiles
      );
    }
  }, [newSecondSlider]);

  useEffect(() => {
    if (newGallery) {
      uploadImageCategory(
        databaseTrademarks,
        setNewTrademarks,
        setDatabaseTrademarks,
        marksFiles
      );
    }
  }, [newGallery]);

  useEffect(() => {
    if (newTrademarks) {
      updateAll();
    }
  }, [newTrademarks]);

  const updateAll = async () => {
    setMainFiles([]);
    setSecondFiles([]);
    setGalleryFiles([]);
    setMarksFiles([]);
    await axios
      .post(`${"https://apisedi.sedihisham.com"}/pages/home/create`, {
        image_slider_url: newMainSlider,
        video_link_home_page: [mainLink],
        catalog_url: newCatalogue[0],
        image_slider2_url: newSecondSlider,
        images_product_url: newGallery,
        videolinks: [galleryLink1, galleryLink2, galleryLink3, galleryLink4],
        trademarks_imag_url: newTrademarks,
      })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Your changes had been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((err) => {
        // inform the user
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <div>
      <div className="flex">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">1.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">
            Home page main slider
          </p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images of product slider ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={mainFiles}
              filesData={mainFilesData}
              setFilesData={setMainFilesData}
              setFiles={setMainFiles}
              removeFile={removeMainFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseMainSlider}
              databaseFiles={databaseMainSlider}
              files={mainFiles}
              filesData={mainFilesData}
              removeFile={removeMainFile}
            />
          </div>
          <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Video Link for the main Slider
          </div>
          <input
            disabled={disabled}
            className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
            placeholder="Copy URL for the Video Here"
            onChange={(e) => {
              setMainLink(e.target.value);
            }}
            value={mainLink}
            name="mainLink"
            type="text"
          />
        </div>
      </div>
      <div className="flex mt-[60px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">2.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">
            Download the catalog
          </p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select a PDF of Product :
          </p>
          <div className="">
            <div className=" w-[130px] h-[30px] mt-[10px]">
              <FileUpload
                setDatabaseFile={setDatabaseCatalogue}
                files={catalogue}
                type="application/pdf"
                setFiles={setCatalogue}
                setFileData={setCatalogueData}
                removeFile={removeCatalogue}
              />
            </div>
            <div className>
              <FileItem
                removeDatabaseFile={removeDatabaseCatalogue}
                databaseFile={databaseCatalogue}
                fileData={null}
                type="pdf"
                file={catalogue?.[0]}
                removeFile={removeCatalogue}
                index="0"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-[60px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">3.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">
            Home Page Second Slider
          </p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images for the second slider ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={secondFiles}
              filesData={secondFilesData}
              setFilesData={setSecondFilesData}
              setFiles={setSecondFiles}
              removeFile={removeSecondFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseSecondSlider}
              databaseFiles={databaseSecondSlider}
              files={secondFiles}
              filesData={secondFilesData}
              removeFile={removeSecondFile}
            />
          </div>
        </div>
      </div>
      <div className="flex mt-[60px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">4.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">
            Photos & Videos Gallery
          </p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images for the gallery ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={galleryFiles}
              filesData={galleryFilesData}
              setFilesData={setGalleryFilesData}
              setFiles={setGalleryFiles}
              removeFile={removeGalleryFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseGallery}
              databaseFiles={databaseGallery}
              files={galleryFiles}
              filesData={galleryFilesData}
              removeFile={removeGalleryFile}
            />
          </div>
          <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Video Links
          </div>
          <span className="mr-[10px] text-[#000] text-[14px] font-[400]">
            1
          </span>
          <input
            disabled={disabled}
            className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
            placeholder="Copy URL for the Video Here"
            onChange={(e) => {
              setGalleryLink1(e.target.value);
            }}
            value={galleryLink1}
            name="galleryLink1"
            type="text"
          />
          <br />
          <span className="mr-[10px] text-[#000] text-[14px] font-[400]">
            2
          </span>
          <input
            disabled={disabled}
            className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
            placeholder="Copy URL for the Video Here"
            onChange={(e) => {
              setGalleryLink2(e.target.value);
            }}
            value={galleryLink2}
            name="galleryLink2"
            type="text"
          />
          <br />
          <span className="mr-[10px] text-[#000] text-[14px] font-[400]">
            3
          </span>
          <input
            disabled={disabled}
            className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
            placeholder="Copy URL for the Video Here"
            onChange={(e) => {
              setGalleryLink3(e.target.value);
            }}
            value={galleryLink3}
            name="galleryLink3"
            type="text"
          />
          <br />
          <span className="mr-[10px] text-[#000] text-[14px] font-[400]">
            4
          </span>
          <input
            disabled={disabled}
            className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
            placeholder="Copy URL for the Video Here"
            onChange={(e) => {
              setGalleryLink4(e.target.value);
            }}
            value={galleryLink4}
            name="galleryLink4"
            type="text"
          />
        </div>
      </div>

      <div className="flex mb- mt-[60px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">5.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">Trademarks</p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images of trademarks ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={marksFiles}
              filesData={marksFilesData}
              setFilesData={setMarksFilesData}
              setFiles={setMarksFiles}
              removeFile={removeMarksFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseTrademarks}
              databaseFiles={databaseTrademarks}
              files={marksFiles}
              filesData={marksFilesData}
              removeFile={removeMarksFile}
            />
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="flex pb-[10px] float-right mr-[80px] mt-[20px]">
        <div className="cursor-pointer rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
          Cancel
        </div>
        <div
          onClick={uploadImages}
          className="cursor-pointer rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default HomePage;

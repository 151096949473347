const style = {
  border: "none",
  color: "#0F3F62",
  fontSize: "13px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "id",
    header: <h3 style={{ fontWeight: "700" }}>ID</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
  },
  {
    name: "locationName",
    header: (
      <h3 style={{ fontWeight: "700", fontFamily: "Poppins" }}>
        Location Name
      </h3>
    ),
    defaultFlex: 1,
    headerProps: { style: style },
    className: "text-[14px] font-[400] font-arabic text-[#0388CC]",
  },
  {
    name: "additionalFee",
    header: <h3 style={{ fontWeight: "700" }}>Additional Fee</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
  },
  {
    name: "creationDate",
    header: <h3 style={{ fontWeight: "700" }}>Creation Date</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "font-[400]",
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
];

export default columns;

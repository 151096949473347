import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import "./FileItem.css";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
const FileItem = ({
  file,
  removeFile,
  fileData,
  index,
  type,
  removeDatabaseFile,
  databaseFile,
}) => {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  return (
    <>
      {(file?.length !== 0 || fileData) && (
        <li
          className="file-item w-[200px] mt-[20px] mr-[90px] text-[14px] items-center flex"
        >
          {fileData && file && (
            <>
              <img className="w-[50px] " src={fileData} />
              <p className="w-[130px] font-thin text-[#0388CC] ml-[10px]">
                {file?.name}
              </p>
            </>
          )}

          {file && type === "pdf" && (
            <>
              <a>
                <img className="w-[40px] " src="./images/pdficon.png" />
              </a>
              <p className="w-[130px] font-thin text-[#0388CC] ml-[10px]">
                {file.name}
              </p>
            </>
          )}

          {file && type === "excel" && (
            <>
              <a>
                <img className="w-[40px] " src="./images/excel.png" />
              </a>
              <p className="w-[130px] font-thin text-[#0388CC] ml-[10px]">
                {file.name}
              </p>
            </>
          )}

          <div className="actions cursor-pointer">
            <div className="loading"></div>
            {file?.isUploading && (
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => !disabled && removeFile(file?.name, index)}
              />
            )}
          </div>
        </li>
      )}

      {databaseFile?.[0] && databaseFile.length !== 0 ? (
        <li
          className="file-item w-[200px] mr-[90px] text-[14px] items-center flex"
          key="{file?.name}"
        >
          {!type && (
            <img
              className="w-[50px] "
              src={"https://apisedi.sedihisham.com" + "/" + databaseFile[0]}
            />
          )}
          {type === "pdf" && (
            <a
              target="_blank"
              href={"https://apisedi.sedihisham.com" + "/" + databaseFile[0]}
            >
              <img className="w-[40px] " src="./images/pdficon.png" />
            </a>
          )}

          <p className="w-[130px] truncate font-thin text-[#0388CC] ml-[10px] ">
            {databaseFile[0]?.split("/").slice(-1)[0]?.split(".")[0].slice(0,-36)+"." +databaseFile[0]?.split("/").slice(-1)[0]?.split(".")[1]}
          </p>
          <div className="actions cursor-pointer">
            <div className="loading"></div>
            <FontAwesomeIcon
              icon={faTrashCan}
              onClick={() => !disabled && removeDatabaseFile(0)}
            />
          </div>
        </li>
      ) : null}
    </>
  );
};

export default FileItem;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectUser } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import NetworkError from "../Modals/NetworkError";
import Loading from "../Loading";
import Swal from "sweetalert2";
function Preferences() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["Admin"].includes(adminRole);
  const [values, setValues] = useState({});

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const fetchPreferences = async () => {
    await axios
      .get("https://apisedi.sedihisham.com" + "/settings/findall")
      .then((res) => {
        setValues({
          currency: res.data.currency,
          vat: res.data.vat,
          vat_value: res.data.vat_value,
          points: res.data.points,
          storeInventory: res.data.storeInventory,
          stocksAlerts: res.data.stocksAlerts,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post("https://apisedi.sedihisham.com" + "/settings/update", {
        ...values,
        stockAlerts: values.stocksAlerts,
      })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <form onSubmit={saveChanges}>
      <div>
        <div className="flex">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">1.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">Language</p>
            <select className="w-[200px] rounded-md px-1 mt-4 font-[400] text-[14px] text-[#000]">
              <option value="en">English</option>
            </select>
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">2.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">Currency</p>
            <select
              required
              disabled={disabled}
              onChange={changeHandler}
              name="currency"
              value={values.currency}
              className="w-[200px] rounded-md px-1 mt-4 font-[400] text-[14px] text-[#000]"
            >
              <option value="">Select your currency</option>
              <option value="AED">AED</option>
              <option value="SYP">SYP</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
            </select>
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">3.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">Vat</p>
            <div clasName="items-center">
              <span className="text-[#000] font-[400] text-[14px] mr-[150px]">
                Turn On/Off Tax
              </span>
              <label
                htmlFor="Toggle1"
                className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100"
              >
                <span className="relative">
                  <input
                    disabled={disabled}
                    value={values.vat}
                    name="vat"
                    checked={!values.vat}
                    onChange={() => {
                      setValues({ ...values, vat: !values.vat });
                    }}
                    id="Toggle1"
                    type="checkbox"
                    className="hidden peer"
                  />
                  <div className="w-12 h-6 rounded-full shadow-inner dark:bg-[green] peer-checked:dark:bg-[red]"></div>
                  <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto dark:bg-[white]"></div>
                </span>
              </label>
            </div>
            <div clasName="items-center mt-3">
              <span className="text-[#000] font-[400] text-[14px] mr-[155px]">
                Turn Value (%)
              </span>
              <input
                required
                disabled={!values.vat || disabled}
                onChange={changeHandler}
                value={values.vat_value}
                name="vat_value"
                placeholder="%"
                className="w-[80px] font-[400] text-[15px] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                type="number"
                min="0"
                max="100"
              />
            </div>
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">4.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">Point System</p>
            <div clasName="items-center">
              <span className="text-[#000] font-[400] text-[14px] mr-[85px]">
                Turn On/Off Point System
              </span>
              {/* <span className="text-[#000] font-[400] text-[14px]">On</span> */}
              <label
                htmlFor="Toggle2"
                className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100"
              >
                <span className="relative">
                  <input
                    disabled={disabled}
                    value={values.points}
                    name="points"
                    checked={!values.points}
                    onChange={() => {
                      setValues({ ...values, points: !values.points });
                    }}
                    id="Toggle2"
                    type="checkbox"
                    className="hidden peer"
                  />
                  <div className="w-12 h-6 rounded-full shadow-inner dark:bg-[green] peer-checked:dark:bg-[red]"></div>
                  <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto dark:bg-[white]"></div>
                </span>
              </label>
              {/* <span className="text-[#000] font-[400] text-[14px]">Off</span> */}
            </div>
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">5.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">
              Store Inventory
            </p>
            <div clasName="items-center">
              <span className="text-[#000] font-[400] text-[14px] mr-[70px]">
                Turn On/Off Store Inventory
              </span>
              {/* <span className="text-[#000] font-[400] text-[14px]">On</span> */}
              <label
                htmlFor="Toggle3"
                className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100"
              >
                <span className="relative">
                  <input
                    disabled={disabled}
                    value={values.storeInventory}
                    name="storeInventory"
                    checked={!values.storeInventory}
                    onChange={() => {
                      setValues({
                        ...values,
                        storeInventory: !values.storeInventory,
                      });
                    }}
                    id="Toggle3"
                    type="checkbox"
                    className="hidden peer"
                  />
                  <div className="w-12 h-6 rounded-full shadow-inner dark:bg-[green] peer-checked:dark:bg-[red]"></div>
                  <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto dark:bg-[white]"></div>
                </span>
              </label>
              {/* <span className="text-[#000] font-[400] text-[14px]">Off</span> */}
            </div>
            <div clasName="items-center">
              <span className="text-[#000] font-[400] text-[14px] mr-[85px]">
                Turn On/Off Stocks Alerts
              </span>
              {/* <span className="text-[#000] font-[400] text-[14px]">On</span> */}
              <label
                htmlFor="Toggle4"
                className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100"
              >
                <span className="relative">
                  <input
                    disabled={disabled}
                    value={values.stocksAlerts}
                    name="stocksAlerts"
                    checked={!values.stocksAlerts}
                    onChange={() => {
                      setValues({
                        ...values,
                        stocksAlerts: !values.stocksAlerts,
                      });
                    }}
                    id="Toggle4"
                    type="checkbox"
                    className="hidden peer"
                  />
                  <div className="w-12 h-6 rounded-full shadow-inner dark:bg-[green] peer-checked:dark:bg-[red]"></div>
                  <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto dark:bg-[white]"></div>
                </span>
              </label>
              {/* <span className="text-[#000] font-[400] text-[14px]">Off</span> */}
            </div>
          </div>
          {showError ? <NetworkError setShowModal={setShowError} /> : null}
          {loading ? <Loading /> : null}
        </div>
        <div className="absolute flex pb-[10px] right-[80px] bottom-[10px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default Preferences;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/couponColumns";
import DeleteWarning from "../Modals/DeleteWarning";

import {
  faCirclePlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewCoupon from "../Modals/NewCoupon";
import CouponStatus from "../CouponStatus";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
function Coupons() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const [showModal, setShowModal] = React.useState(false);
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;

  const [coupons, setCoupons] = useState([]);
  var data = [];
  const fetchCoupons = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/coupons/findall`
    );
    const data = response.data;
    if (data.length !== coupons.length) {
      setCoupons(data);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [coupons]);

  useEffect(() => {
    dispatch(setSearch(""))
  }, []);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(coupons);
  }, [coupons]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        coupons.filter((coupon) => {
          return coupon.coupon_code.includes(searchInput);
        })
      );
    } else {
      setFilteredData(coupons);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const coupon = filteredData[i];
    data = [
      ...data,
      {
        couponCode: coupon.coupon_code,
        type: coupon.type_coupon,
        value: parseInt(coupon.value),
        products: coupon.productAffected?.map((Product) => Product.name + ", "),
        creationDate: coupon.createdAt.slice(0, 10),
        expirationDate: coupon.expiry_date.slice(0, 10),
        status: (
          <CouponStatus
            disabled={!["Sales", "Admin"].includes(adminRole)}
            key={coupon.status}
            couponId={coupon.id}
            status={coupon.status}
          />
        ),
        view: (
          <Link
            to={
              ["Sales", "Admin"].includes(adminRole)
                ? `/coupon-details?id=${coupon.id}`
                : "/offers?tab=2"
            }
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
          onClick={() => {
            if (["Sales", "Admin"].includes(adminRole)) {
              setItemToDelete({ id: coupon.id, index: i });
              setShowWarningModal(true);
            }
          }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }
  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewCoupon
          coupons={coupons}
          setCoupons={setCoupons}
          setShowModal={setShowModal}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/coupons/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={coupons}
          setItems={setCoupons}
        />
      ) : null}
      <div
        onClick={() =>
          ["Sales", "Admin"].includes(adminRole) && setShowModal(true)
        }
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new coupon </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default Coupons;

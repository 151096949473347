import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../ContactForm";
import NewContact from "../Modals/NewContact";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
// import { Dialog } from "@headlessui/react";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError"
import Swal from 'sweetalert2'
function ContactUsPage() {
  const [showError, setShowError] =useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const [showModal, setShowModal] = React.useState(false);
  const [contacts, setContacts] = useState([]);
  const [values, setValues] = useState({
    name: "",
    description: "",
    Location: "",
    openings_time: "",
    phone_number: "",
    fax: "",
    delivery_time: "",
    additional_fees: 0,
  });
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const addContact = () => {
    if (
      values.name !== "" &&
      values.description !== "" &&
      values.Location !== "" &&
      values.openings_time !== "" &&
      values.phone_number !== "" &&
      values.fax !== "" &&
      values.delivery_time !== ""
    ) {
      setContacts([...contacts, values]);
      setShowModal(false);
    } else {
      setError("Please fill all required fields");
    }
  };

  const deleteContact = (index) => {
    setContacts(contacts.filter((contact, i) => i !== index));
  };

  const fetchContacts = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/branches/getallbranches`
    );
    const data = response.data;
    if (data?.length !== contacts?.length) {
      setContacts(data);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const submitContacts = async () => {
    setLoading(true);
    await axios
      .post(`${"https://apisedi.sedihisham.com"}/pages/contact_us/create`, {
        locations_arr: contacts,
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Your changes had been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((err) => {
        console.log(err)
        if (err.code==="ERR_NETWORK"){
          setLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000);
        }        
      });
  };

  return (
    <div>
      <div className="divide-y">
        {contacts.map((contact, i) => (
          <ContactForm
            disabled={true}
            contact={contact}
            deleteContact={deleteContact}
            key={i}
            index={i}
          />
        ))}
      </div>
      {showModal ? (
        <NewContact
          error={error}
          values={values}
          setValues={setValues}
          changeHandler={changeHandler}
          addContact={addContact}
          setShowModal={setShowModal}
        />
      ) : null}
      <div
        onClick={() => !disabled && setShowModal(true)}
        className="cursor-pointer  flex items-center text-[#0388CC] h-[30px]  text-[13px]"
      >
        <div className="mr-2">Add new contact </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
      {loading && <Loading />}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="flex pb-[10px] float-right mr-[80px] mt-[20px]">
        <div className="cursor-pointer rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
          Cancel
        </div>
        <div
          onClick={submitContacts}
          className="cursor-pointer rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;

import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect } from "react";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from "sweetalert2";
function Careers() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [backgroundImageData, setBackgroundImageData] = useState([]);
  const removeBackgroundImage = (filename, index) => {
    setBackgroundImage(null);
    setBackgroundImageData(null);
  };

  const [databaseBackgroundImage, setDatabaseBackgroundImage] = useState([]);
  const removeDatabaseBackgroundImage = (index) => {
    setDatabaseBackgroundImage(
      databaseBackgroundImage.filter((file, i) => i !== index)
    );
  };

  const fetchBackgroundImage = async () => {
    await axios
      .get("https://apisedi.sedihisham.com" + "/pages/getall/career")
      .then((Response) => {
        setDatabaseBackgroundImage([Response.data[0].path_image]);
      });
  };

  useEffect(() => {
    fetchBackgroundImage();
  }, []);
  useEffect(() => {}, [databaseBackgroundImage]);

  const updateBackgroundImage = async (e) => {
    e.preventDefault();
    if (backgroundImage.length !== 0) {
      setError(null);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", backgroundImage[0], backgroundImage[0]?.name);
      axios
        .post(
          `${"https://apisedi.sedihisham.com"}/pages/upload/career-page/image`,
          formData
        )
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Your changes had been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
        });
    } else if (
      backgroundImage.length === 0 &&
      databaseBackgroundImage.length === 0
    ) {
      setError("Please add an image first.");
    }
  };

  return (
    <form onSubmit={updateBackgroundImage}>
      <div>
        <div className="flex">
          <div>
            <div className="text-[#000] font-[700] text-[14px]">1.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#000] font-[700] text-[14px]">
              Background Image
            </p>
            <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
              Select a Background Image ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[10px]">
              <FileUpload
                required={
                  backgroundImage?.length === 0 &&
                  databaseBackgroundImage?.length === 0
                }
                setDatabaseFile={setDatabaseBackgroundImage}
                files={backgroundImage}
                setFiles={setBackgroundImage}
                setFileData={setBackgroundImageData}
                removeFile={removeBackgroundImage}
              />
            </div>
            <div className>
              <FileItem
                removeDatabaseFile={removeDatabaseBackgroundImage}
                databaseFile={databaseBackgroundImage}
                fileData={backgroundImageData}
                file={backgroundImage?.[0]}
                removeFile={removeBackgroundImage}
                index="0"
              />
            </div>
          </div>
        </div>
        {error ? (
          <p className="text-[14px] ml-4 font-[400] mt-2 text-[red]">
            <FontAwesomeIcon icon={faCircleExclamation} /> {error}
          </p>
        ) : null}
        {loading && <Loading />}
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default Careers;

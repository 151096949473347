import React, { useState } from "react";
import styles from "../../styles/settingsTabs.module.css";
import FileMultiUpload from "../FileUploader/FileMultiUpload";
import FileList from "../FileUploader/FileList";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function Images({ files, setFiles, removeDatabaseFile, databaseFile }) {
  const [filesData, setFilesData] = useState([]);
  const removeFile = (filename, index) => {
    setFiles(files.filter((file, i) => i !== index));
    setFilesData(filesData.filter((fileData, i) => i !== index));
  };
  return (
    <div key={11} className={`${styles.tab} flex items-center`}>
      <input
        value="11"
        type="radio"
        name="settings-tabs"
        id="11"
        defaultChecked
        className={`${styles.tabSwicth}`}
      />
      <label htmlFor="11" id="11" className={`${styles.tabLabel}`}>
        <span className={`${styles.tabSpan} font-[400] text-[14px]`}>
          Images
        </span>
      </label>
      <div className={`${styles.tabContent}`}>
        <span>
          <div>
            <p className="text-[#000] text-[14px] font-[400]">
              Select a images of Product ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[10px]">
              <FileMultiUpload
                required={files?.length === 0 && databaseFile?.length === 0}
                files={files}
                filesData={filesData}
                setFilesData={setFilesData}
                setFiles={setFiles}
                removeFile={removeFile}
              />
            </div>
            <div>
              <FileList
                removeDatabaseFile={removeDatabaseFile}
                databaseFiles={databaseFile}
                files={files}
                filesData={filesData}
                removeFile={removeFile}
              />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default Images;

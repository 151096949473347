import React, { useState } from "react";
import { Link, Router, useNavigate } from "react-router-dom";
import FormRow from "./FormRow";
import { useDispatch } from "react-redux";
import { login } from "../redux/slices/userSlice";

function LoginForm() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch =  useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = values;
    if (email === "" || password === "") {
      setError(".الرجاء تعبئة جميع الحقول");
    } else {
      dispatch(login({ email, password }))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            setError(".البريد الإلكتروني أو كلمة السر غير صحيحين");
          } else if (response.meta.requestStatus === "fulfilled") {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="relative flex flex-col justify-center overflow-hidden">
      <div className=" p-6 m-auto   w-[80%] bg-white rounded-md   ">
        <p className="text-xl flex  font-arabicBold tracking-wide justify-end items-center text-[#007530]">
          تسجيل الدخول إلى حسابك
        </p>

        <form className="mt-10" onSubmit={onSubmit}>
          <FormRow
            changeHandler={changeHandler}
            setValues={setValues}
            values={values}
            name="email"
            label=" البريد الاكتروني"
            inputType="email"
          />
          <FormRow
            changeHandler={changeHandler}
            setValues={setValues}
            values={values}
            name="password"
            label="كلمة المرور"
            inputType="password"
          />

          {/* <a
            href="#"
            className="flex justify-end my-2 text-xs text-red-600 font-arabicLight hover:underline"
          >
            هل نسيت كلمة المرور
          </a> */}
          <div className="mt-6">
            <input
              type="submit"
              className=" w-[60%] mx-[20%] font-arabicMedium flex justify-center items-center  rounded-full  px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#007530]  hover:bg-green-600 "
              value="تسجيل الدخول"
            />
          </div>
          {error ? (
            <p className="text-center m-auto p-2 rounded-lg text-[15px] text-[#fff] mt-4 bg-[#ff4d4d] w-[60%]">
              {error}
            </p>
          ) : null}
        </form>

        {/* <p className="mt-8 text-xs font-light text-center text-gray-700">
          {" "}
          ليس لديك حساب؟{" "}
          <a href="#" className="font-medium text-red-600 hover:underline">
            اشتراك الان
          </a>
        </p> */}
      </div>
    </div>
  );
}

export default LoginForm;

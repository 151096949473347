import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs({ breads }) {
  return (
    <div className="flex text-[16px]">
      <Link to={breads.length > 1 ? breads[breads.length-2].link : "/" }>
        <div className="mr-2 text-[#7C8DB5]">
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </div>
      </Link>
      {breads.map((bread, i) =>
        i == 0 ? (
          <div>
            <Link to={bread.link}>{bread.title}</Link>{" "}
          </div>
        ) : (
          <div>
            <span className="text-[#7C8DB5]">{" / "}</span>
            <Link to={bread.link}>{bread.title}</Link>{" "}
          </div>
        )
      )}
    </div>
  );
}

export default Breadcrumbs;

import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "../components/SideBar";
import Tab from "../components/Tab";
import styles from "../styles/tabs.module.css";

import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCooksCategory } from "../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import Breadcrumbs from "../components/Breadcrumbs";
import SearchBar from "../components/SearchBar";
import UserIcon from "../components/UserIcon";
import Footer from "../components/Footer";
function CooksCategoryDetails(props) {
  const selectedCooksCategory = useSelector(selectCooksCategory);
  const handleCheck = (e) => {
    setSelectedTab(e.target.value);
  };

  const tabs = {
    1: "Cooks By Category",
    2: "Edit Cooks Category",
  };
  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let tab = queryParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(tab);

  useEffect(() => {
    setSelectedTab(queryParams.get("tab"));
  }, [tab]);

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Cooks Categories",
                  link: "/cooks-categories",
                },
                {
                  title: `Cooks Category Details (${selectedCooksCategory.id})`,
                  link: `/cooks-category-details?tab=1`,
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              {selectedTab!=="2" && <SearchBar placeholder="Search by cook name"/>}
              <UserIcon/>
            </div>
          </div>
          <div className="bg-[white] grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className={`${styles.tabs} rounded-xl`}>
              <div
                className={`${styles.tab} rounded-tl-xl pl-[20px] text-[18px] py-[10px] ${styles.selected}`}
              >
                {tabs[selectedTab]}
              </div>
              <Tab
                content="CooksByCategory"
                selectedTab={selectedTab}
                labelId="label-1"
                title="Cooks By Category"
                value="1"
                handleCheck={handleCheck}
                url="/cooks-category-details?tab=1"
              />
              <Tab
                content="EditCooksCategory"
                selectedTab={selectedTab}
                title="Edit Cooks Category"
                value="2"
                handleCheck={handleCheck}
                url="/cooks-category-details?tab=2"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CooksCategoryDetails;

import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { selectProduct } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import NetworkError from "../Modals/NetworkError";
import Loading from "../Loading";
import Swal from "sweetalert2";
function StockInventory() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedProduct = useSelector(selectProduct);
  const [values, setValues] = useState({
    quantity: "",
    date: "",
  });
  const [stockHistory, setStockHistory] = useState([]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const fetchStockHistory = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/products/stocks/${selectedProduct.id}`
    );
    const data = response.data;
    setStockHistory(data);
  };
  useEffect(() => {
    fetchStockHistory();
  }, []);

  const addStock = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(
        `${"https://apisedi.sedihisham.com"}/products/stock/create/${selectedProduct.id}`,
        {
          stock_added: parseInt(values.quantity),
          date: values.date,
        }
      )
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
    fetchStockHistory();
  };
  return (
    <div className="flex h-full justify-evenly divide-x">
      <div className="h-full pr-[30px] w-[45%] flex flex-col justify-between">
        <form onSubmit={addStock}>
          <div>
            <div className="text-[17px] font-[400] text-[#0388CC]">
              Add or Remove Stocks for this Product
            </div>
            <div className="text-[#000] mt-6 text-[14px] font-[400]">
              Quantity:
              <br />
              <input
                required
                className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="How many items you will add"
                onChange={changeHandler}
                value={values.quantity}
                name="quantity"
                type="number"
              />
            </div>
            <div className="text-[#000] mt-6 text-[14px] font-[400]">
              Date:
              <br />
              <input
                required
                className="h-[30px] mt-[5px] placeholder:text-[13px] text-[13px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                type="date"
                onChange={changeHandler}
                value={values.date}
                name="date"
              />
            </div>
            <input
              type="submit"
              className="w-fit mt-6 h-[25px] flex items-center cursor-pointer font-[200] text-[center] text-[14px] px-8 rounded-full bg-[#007530] text-[white]"
              value=" Add new stock to inventory"
            />
          </div>
        </form>
        <div className="flex">
          <div className="mr-6">
            <img src="/images/dashboard/in-stock-products.svg" />
          </div>
          <div>
            <span className="text-[20px]">{stockHistory.sales} </span>
            <br />
            <span className="text-[12px] font-[400] text-[green]">Sales</span>
          </div>
        </div>
      </div>
      <div className="h-full pl-[100px] w-[45%] flex flex-col justify-between">
        <div>
          <div className="text-[17px] font-[400] text-[#0388CC]">
            Stock History
          </div>
          <div className="mt-3 h-[460px] scrollbar-thin scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            {stockHistory.stocksHistory?.length !== 0 ? (
              <table className="text-[13px] text-[#000] text-left w-full">
                <thead>
                  <tr>
                    <th className="text-[14px] w-[80%] font-[400] py-2">
                      Quantity
                    </th>
                    <th className="text-[14px]  font-[400] py-2">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {stockHistory.stocksHistory?.map((stock) => (
                    <tr>
                      {stock.stockadded < 0 ? (
                        <td className="py-2 text-[red]">{stock.stockadded}</td>
                      ) : (
                        <td className="py-2">{stock.stockadded}</td>
                      )}

                      <td className="py-2 font-[400]">
                        {stock.createdAt.slice(0, 10)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-[14px] font-[400]">Stock history is empty</p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="mr-6 ">
            <img
              className="h-[40px]"
              src="/images/dashboard/active-products.svg"
            />
          </div>
          <div>
            <span className="text-[20px]">{stockHistory.stock} </span>
            <br />
            <span className="text-[12px] font-[400] text-[#0388CC]">
              Products in Stock
            </span>
          </div>
          {loading ? <Loading /> : null}
          {showError ? <NetworkError setShowModal={setShowError} /> : null}
        </div>
      </div>
    </div>
  );
}

export default StockInventory;

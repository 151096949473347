import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import "./FileItem.css";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
const DatabaseFileItem = ({
  file,
  removeFile,
  fileData,
  index,
  type,
  removeDatabaseFile,
}) => {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  return (
    <>
      <i className="file-item w-[200px] mt-[30px] mr-[90px] text-[14px] items-center flex">
        <img className="w-[50px] " src={fileData} />

        <p className="w-[130px] font-thin truncate text-[#0388CC] ml-[20px]">
          {fileData.split("/").slice(-1)[0].split(".")[0].slice(0, -36) +
            "." +
            fileData.split("/").slice(-1)[0].split(".")[1]}
        </p>
        <div className="cursor-pointer">
          <FontAwesomeIcon
            icon={faTrashCan}
            onClick={() => !disabled && removeDatabaseFile(index)}
          />
        </div>
      </i>
    </>
  );
};

export default DatabaseFileItem;

const style = {
  border: "none",
  color: "#033362",
  fontSize: "14px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "id",
    header: <h3 style={{ fontWeight: "700" }}>ID</h3>,
    defaultFlex: 0.6,
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
    type:"number"
  },
  {
    name: "name",
    header: <h3 style={{ fontWeight: "700" }}>Name</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "email",
    header: <h3 style={{ fontWeight: "700" }}>Email</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#000]"
  },
  {
    name: "age",
    header: <h3 style={{ fontWeight: "700" }}>Age</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "phone",
    header: 'Phone Number',
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] font-[400] w-fit font-[700] rounded text-[#0388CC] "
  },
  {
    name: "jobPosition",
    header: <h3 style={{ fontWeight: "700" }}>Job Position</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "applicationDate",
    header: <h3 style={{ fontWeight: "700" }}>Application Date</h3>,
    defaultFlex: 1.6,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "status",
    header: <h3 style={{ fontWeight: "700" }}>Status</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.7,
    headerProps: { style: style },
  },
];

export default columns;

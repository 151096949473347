import React, { useState } from "react";
import styles from "../../styles/settingsTabs.module.css";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function SKU({
  sku,
  setSku,
  skuCode,
  setSkuCode,
  databaseFile,
  removeDatabaseFile,
  setDatabaseFile,
}) {
  const [skuData, setSkuData] = useState(null);

  const removeFile = (filename) => {
    setSku(null);
  };
  return (
    <div key={44} className={`${styles.tab} flex items-center`}>
      <input
        value="44"
        type="radio"
        name="settings-tabs"
        id="44"
        className={`${styles.tabSwicth}`}
      />
      <label htmlFor="44" id="44" className={`${styles.tabLabel}`}>
        <span className={`${styles.tabSpan} font-[400] text-[14px]`}>SKU</span>
      </label>
      <div className={`${styles.tabContent}`}>
        <span>
          <div>
            <div className="text-[#000] text-[14px] font-[400]">
              <span>
                SKU Code:<span className="text-[red] font-[700] mr-4">*</span>
              </span>
              <input
                required
                className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter SKU code"
                onChange={(e) => {
                  setSkuCode(e.target.value);
                }}
                value={skuCode}
                type="text"
              />
            </div>
            <p className="text-[#000] text-[14px] mt-3 font-[400]">
              SKU Photo for this product:
              <span className="text-[red] font-[700]">*</span>
            </p>

            <div className=" w-[130px] h-[30px] mt-[10px]">
              <FileUpload
                required={sku?.length === 0 && databaseFile?.length === 0}
                setDatabaseFile={setDatabaseFile}
                files={sku}
                setFiles={setSku}
                setFileData={setSkuData}
                removeFile={removeFile}
              />
            </div>
            <FileItem
              removeDatabaseFile={removeDatabaseFile}
              databaseFile={databaseFile}
              fileData={skuData}
              file={sku?.[0]}
              removeFile={removeFile}
              index="0"
            />
          </div>
        </span>
      </div>
    </div>
  );
}

export default SKU;

import React from "react";
import "../styles/loading.css";

function Loading() {
  return (
    <div className="loadingio-spinner-spinner-5pvok04ktu3">
      <div className="ldio-h6vfruwm00t">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SideBar from "../components/SideBar";

import {
  faUser,
  faCaretDown,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileList from "../components/FileUploader/FileList";
import RichTextEditor from "../components/RichTextEditor";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUploader/FileUpload";
import FileItem from "../components/FileUploader/FileItem";
import Loading from "../components/Loading";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import NetworkError from "../components/Modals/NetworkError";
import Swal from "sweetalert2";
import FileMultiUpload from "../components/FileUploader/FileMultiUpload";
import Footer from "../components/Footer";
function ActivityEdit() {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let activityId = queryParams.get("id");

  const [descAr, setDescAr] = useState(false);
  const [descEn, setDescEn] = useState(false);
  const [activity, setActivity] = useState(null);
  const fetchActivity = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/activities/find/${activityId}`
    );
    setValues({
      titleAr: response.data.activity_translations[0]?.title,
      titleEn: response.data.activity_translations[1]?.title,
      category: response.data.category,
      videoLink: response.data.video_url,
    });
    setDescAr(response.data.activity_translations[0]?.descrition);
    setDescEn(response.data.activity_translations[1]?.descrition);
    setActivity(response.data);
    let images = [];
    response.data.image_url.map((item) => {
      images = [...images, item.image_url];
    });
    setDatabaseSlider(images);
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  //Slider Files
  const [activitySlider, setActivitySlider] = useState([]);
  const [activitySliderData, setActivitySliderData] = useState([]);
  const removeActivityImage = (filename, index) => {
    setActivitySlider(null);
    setActivitySliderData(null);
  };

  //NEW FILES
  const [newSlider, setNewSlider] = useState();

  // Database files

  const [databaseSlider, setDatabaseSlider] = useState([]);
  const removeDatabaseImage = (index) => {
    setDatabaseSlider(databaseSlider.filter((file, i) => i !== index));
  };

  const updateNews = (e) => {
    e.preventDefault();
    if (
      (activitySlider.length !== 0 || databaseSlider.length !== 0) &&
      values.titleAr !== "" &&
      values.titleEn !== "" &&
      values.category !== "" &&
      descAr !== "" &&
      descEn !== ""
    ) {
      setError(null);
      setLoading(true);
      uploadImageCategory(
        databaseSlider,
        setNewSlider,
        setDatabaseSlider,
        activitySlider
      );
    } else {
      setError("Please all the required fields.");
    }
  };

  useEffect(() => {
    if (newSlider) {
      uploadAll();
    }
  }, [newSlider]);

  const uploadImageCategory = async (
    imageCategory,
    setNewImagecategory,
    setDatabaseSlidercategory,
    uploadedFiles
  ) => {
    var databaseSliders = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const formData = new FormData();
      formData.append("file", uploadedFiles[i], uploadedFiles[i].name);
      await axios
        .post(
          `${"https://apisedi.sedihisham.com"}/cooks/upload/cook-image`,
          formData
        )
        .then((imgResponse) => {
          databaseSliders = [...databaseSliders, imgResponse.data.imagePath];
        })
        .catch((err) => {
          // inform the user
          console.log(err);
        });
    }
    setNewImagecategory([...imageCategory, ...databaseSliders]);
    setDatabaseSlidercategory([...imageCategory, ...databaseSliders]);
  };

  const uploadAll = async () => {
    await axios
      .post(
        `${"https://apisedi.sedihisham.com"}/activities/update/${activityId}`,
        {
          title_en: values.titleEn,
          title_ar: values.titleAr,
          description_en: descEn,
          description_ar: descAr,
          category: values.category,
          image_url: newSlider,
          video_url:values.videoLink,
          isvideo:true
        }
      )
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <form onSubmit={updateNews}>
        <div className="m-auto mt-6 flex">
          <div>
            <SideBar />
          </div>
          <div className="w-full ml-6 text-[#033362] font-semibold">
            <div className="flex justify-between items-center w-full">
              <Breadcrumbs
                breads={[
                  {
                    title: "News",
                    link: "/news?tab=1",
                  },
                  {
                    title: `Activity Details (${activityId})`,
                    link: `/activity-edit?id=${activityId}`,
                  },
                ]}
              />
              <div className="flex items-center h-8 w-[30%]">
                <UserIcon />
              </div>
            </div>
            <div className="bg-[white] h-[694px] mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
              <div className="ml-[8%] scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                <div className="mt-8">
                  <div className="text-[#0388CC] font-[600] text-[14px]">
                    Basic Information
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="title-en"
                      >
                        Title - EN{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="News title in english"
                        onChange={changeHandler}
                        value={values.titleEn}
                        name="titleEn"
                        type="text"
                        id="title-en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="title-ar"
                      >
                        Title - AR{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="News title in arabic"
                        onChange={changeHandler}
                        value={values.titleAr}
                        name="titleAr"
                        type="text"
                        id="title-ar"
                      />
                    </div>
                    <div className="w-[400px] mr-[120px] my-[10px]">
                      <div className="mt-[0px] text-[15px] font-[400] text-[#000]">
                        Category
                        <span className="text-[red] font-[700]">*</span>
                      </div>
                      <input
                        required
                        className="mr-[10px]"
                        type="radio"
                        name="category"
                        checked={values?.category === "national"}
                        onChange={() => {
                          setValues({ ...values, category: "national" });
                        }}
                        value="national"
                        id="national"
                      />
                      
                      <label
                        htmlFor="international"
                        className="text-[14px] font-arabic text-[#0388CC] font-[400]"
                      >
                        معارض عالمية
                      </label>
                      <input
                        required
                        className="mr-[10px] ml-[30px]"
                        type="radio"
                        checked={values?.category === "international"}
                        onChange={() => {
                          setValues({ ...values, category: "international" });
                        }}
                        name="category"
                        value="international"
                        id="international"
                      />
                      <label
                        htmlFor="national"
                        className="text-[14px] font-arabic text-[#0388CC] font-[400]"
                      >
                        معارض سورية
                      </label>
                      
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-[15px] w-[87%]">
                    {descEn !== false && (
                      <RichTextEditor
                        name="Description-EN"
                        initialValue={descEn}
                        getValue={setDescEn}
                      />
                    )}
                    {descAr !== false && (
                      <RichTextEditor
                        name="Description-AR"
                        initialValue={descAr}
                        getValue={setDescAr}
                      />
                    )}
                  </div>
                  <div className="my-6">
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of Product ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[10px]">
                      <FileMultiUpload
                        required={
                          activitySlider?.length === 0 &&
                          databaseSlider?.length === 0
                        }
                        files={activitySlider}
                        filesData={activitySliderData}
                        setFilesData={setActivitySliderData}
                        setFiles={setActivitySlider}
                        removeFile={removeActivityImage}
                      />
                    </div>
                    <div>
                      <FileList
                        removeDatabaseFile={removeDatabaseImage}
                        databaseFiles={databaseSlider}
                        files={activitySlider}
                        filesData={activitySliderData}
                        removeFile={removeActivityImage}
                      />
                    </div>
                    <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
                      Or Use Video Link (Youtube, Vimo...)
                    </div>
                    <input
                      className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="or Copy URL for the Video Here"
                      onChange={changeHandler}
                      value={values.videoLink}
                      name="videoLink"
                      type="url"
                    />
                  </div>
                  {loading ? <Loading /> : null}
                  {error ? (
                    <p className="text-[14px] mt-2 text-[red]">
                      <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                    </p>
                  ) : null}
                  {showError ? (
                    <NetworkError setShowModal={setShowError} />
                  ) : null}
                  <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
                    <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
                      Cancel
                    </div>
                    <input
                      type="submit"
                      className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                      value="Save"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
}

export default ActivityEdit;

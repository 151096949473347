import React from 'react'

export default function ViewMessage({ setViewMessage,data , children}) {
    console.log(data);
  return (
    <>
       <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[50%]">
          <div className="border-0 rounded-xl shadow-lg p-4 relative flex flex-col w-full bg-white outline-none focus:outline-none">
             <div className='flex flex-col gap-6'>
             <div className='flex justify-between align-middle'>
             <h2 className='font-semibold'>Message Details</h2>
             <button onClick={() =>setViewMessage(false)} className=' w-fit ms-auto h-auto'>x</button>
             </div>
             <p>ID : {data.id}</p>
             <p>Name : {data.name}</p>
             <p>Phone Number : {data.phone_number}</p>
             <p>Subject : {data.sujet}</p>
             <p>Content : {data.content}</p>
             <p>created At : {data.createdAt}</p>
             <p>Update At : {data.updatedAt}</p>
             <p>Deleted At : {data.deletedAt} </p>
             </div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

import React from "react";
import { faBell, faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/shipmentFeeColumns";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteWarning from "../Modals/DeleteWarning";
import NewShipment from "../Modals/NewShipment";
import { selectSearch, setSearch } from "../../redux/slices/itemSlice";
function Offers() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  const [fees, setFees] = useState([]);
  const fetchFees = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/shippement/findall`
    );
    const data = response.data;
    if (data.length !== fees.length) {
      setFees(data);
    }
  };

  const [showModal, setShowModal] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  var data = [];
  useEffect(() => {
    fetchFees();
  }, []);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(fees);
  }, [fees]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        fees.filter((fee) => {
          return fee.location_ar.includes(searchInput);
        })
      );
    } else {
      setFilteredData(fees);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const fee = filteredData[i];
    data = [
      ...data,
      {
        id: fee.id,
        locationName: fee.location_ar,
        additionalFee: fee.additional_fees,
        creationDate: fee.createdAt?.slice(0, 10),
        view: (
          <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
            <FontAwesomeIcon icon={faEye} />
          </div>
        ),
        delete: (
          <div
            onClick={() => {
              if (!disabled) {
                setItemToDelete({ id: fee.id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  return (
    <div>
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
        {showModal ? (
          <NewShipment
            shipments={fees}
            setShipments={setFees}
            setShowModal={setShowModal}
          />
        ) : null}
        {showWarningModal ? (
          <DeleteWarning
            url="/shippement/delete/"
            setShowModal={setShowWarningModal}
            itemToDelete={itemToDelete}
            items={fees}
            setItems={setFees}
          />
        ) : null}
      </div>
      <div
        onClick={() => setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] ml-[25px] text-[13px]"
      >
        <div className="mr-2">Add new shipment fee </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default Offers;

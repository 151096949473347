import React, { useEffect, useState } from "react";
import styles from "../styles/sidebar.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";

function SideBar() {
  const [logo, setLogo] = useState(null);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];

  const fetchLogo = async () => {
    await axios
      .get("https://apisedi.sedihisham.com" + "/statistics/logo")
      .then((res) => {
        setLogo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLogo();
  }, []);

  return (
    <div className="w-20 bg-white rounded-xl flex flex-col h-full shadow-[0px_0px_16px_rgb(210,215,211)]">
      <Link to="/">
        <div className="pt-12">
          <img
            className="m-auto w-[85%] drop-shadow-xl"
            src={"https://apisedi.sedihisham.com" + "/" + logo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/logo.png";
            }}
          />
        </div>
      </Link>
      <div className="grow mt-[35px] ">
        <ul className="list-none h-full flex flex-col justify-between  text-[12px] text-[#707070]  ">
          <Link to="/">
            <div
              className={`z-50 py-[15px] text-center  w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto "
                    src="/images/sidebar/home.svg"
                  />
                </div>
                <div>Home </div>
              </li>
            </div>
          </Link>

          <Link to={["Admin", "Sales"].includes(adminRole) ? "/orders" : "#"}>
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/orders.svg"
                  />
                </div>
                <div>
                  Orders{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div className={`${styles.popupWrapper}`}>
                      <div className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "Sales"].includes(adminRole)
                              ? "/orders"
                              : "#"
                          }
                        >
                          <h3 className="text-[13px] text-start font-[500]">
                            View Orders
                          </h3>
                          <p className="text-[8px] text-[#6A707E] text-start leading-3">
                            View process, and print invoices slips for orders
                            places from your website
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link
            to={
              ["Admin", "DataEntry"].includes(adminRole) ? "/categories" : "#"
            }
          >
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/products.svg"
                  />
                </div>
                <div>
                  Products{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div className={`divide-y ${styles.popupWrapper}`}>
                      <div className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/categories"
                              : "#"
                          }
                        >
                          <h3 className="text-[13px] text-start font-[500]">
                            View Categories
                          </h3>
                          <p className="text-[8px] text-[#6A707E] text-start leading-3">
                            View products categories which are used to group
                            products in ypur store
                          </p>
                        </Link>
                      </div>
                      <div className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/products"
                              : "#"
                          }
                        >
                          <h3 className="text-[13px] text-start font-[500]">
                            View Products
                          </h3>
                          <p className="text-[8px] text-[#6A707E] text-start leading-3">
                            View add, and edit products that are shown to the
                            visitoes of your website
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link
            to={
              ["Admin", "DataEntry"].includes(adminRole) ? "/pages?tab=1" : "#"
            }
          >
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/pages.svg"
                  />
                </div>
                <div>
                  Pages{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=1"
                              : "#"
                          }
                        >
                          Home Page
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=2"
                              : "#"
                          }
                        >
                          Products Page
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=3"
                              : "#"
                          }
                        >
                          Contact Us Page
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=4"
                              : "#"
                          }
                        >
                          Who We Are Page
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=5"
                              : "#"
                          }
                        >
                          Privacy Policy
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/pages?tab=6"
                              : "#"
                          }
                        >
                          Careers
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link to={["Admin"].includes(adminRole) ? "/users?tab=1" : "#"}>
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/users.svg"
                  />
                </div>
                <div>
                  Users{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin"].includes(adminRole) ? "/users?tab=1" : "#"
                          }
                        >
                          Administrators
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin"].includes(adminRole) ? "/users?tab=2" : "#"
                          }
                        >
                          Customers
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link
            to={
              ["Admin", "DataEntry"].includes(adminRole) ? "/news?tab=1" : "#"
            }
          >
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/news.svg"
                  />
                </div>
                <div>
                  News{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/news?tab=1"
                              : "#"
                          }
                        >
                          News
                        </Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/news?tab=2"
                              : "#"
                          }
                        >
                          Activities
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link
            to={
              ["Admin", "DataEntry"].includes(adminRole)
                ? "/cooks-categories"
                : "#"
            }
          >
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/kitchen.svg"
                  />
                </div>
                <div>
                  Kitchen{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link
                          to={
                            ["Admin", "DataEntry"].includes(adminRole)
                              ? "/cooks-categories"
                              : "#"
                          }
                        >
                          Cooks Categories
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link to={["Admin", "Messaging"].includes(adminRole) ? "/messages" : "#"}>
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/messages.svg"
                  />
                </div>
                <div>
                  Messages{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "Messaging"].includes(adminRole) ? "/messages" : "#"}>Contact Us Messages</Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "Messaging"].includes(adminRole) ? "/emails" : "#"}>Email List Management</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link to={["Admin", "HR"].includes(adminRole) ? "/careers?tab=1" : "#"}>
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/careers.svg"
                  />
                </div>
                <div>
                  Careers{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "HR"].includes(adminRole) ? "/careers?tab=1" : "#"}>Job Applications</Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "HR"].includes(adminRole) ? "/careers?tab=2" : "#"}>Job Management</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>

          <Link to={["Admin", "Sales"].includes(adminRole) ? "/offers?tab=1" : "#"}>
            <div
              className={`z-50 py-[15px] text-center w-full hover:cursor-pointer hover:rounded-xl hover:bg-[#f7f7f7] ${styles.sideBtn}`}
            >
              <li>
                <div>
                  <img
                    className="w-[16px] m-auto"
                    src="/images/sidebar/offers.svg"
                  />
                </div>
                <div>
                  Offers{" "}
                  <div className={`${styles.popup} ${styles.arrowTop}`}>
                    <div
                      className={`text-[13px] text-start font-[500] divide-y ${styles.popupWrapper}`}
                    >
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "Sales"].includes(adminRole) ? "/offers?tab=1" : "#"}>Offers</Link>
                      </p>
                      <p className="py-4 pl-4">
                        <Link to={["Admin", "Sales"].includes(adminRole) ? "/offers?tab=2" : "#"}>Coupons</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;

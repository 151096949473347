import React from "react";

function Footer() {
  return (
    <div className="w-full mb-[10px] flex justify-center text-[14px] text-[#707070] bg-[#fff] h-7 rounded-xl mt-2 items-center font-normal shadow-[0px_0px_16px_rgb(210,215,211)]">
      Copyright © {new Date().getFullYear()} . Powered by BIGBANG Information Technology Solutions.
    </div>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Loading from "../Loading";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import NetworkError from "./NetworkError";
import MapPicker from "react-google-map-picker";

function MapModel({ setShowModal, setValues, values }) {
  //   const DefaultLocation = { lat: 10, lng: 106 };
  //   const DefaultZoom = 10;
  const [defaultLocation, setDefaultLocation] = useState({ lat: 34.6543174745997, lng: 36.717622369264895 });

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(10);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
    // handleResetLocation()
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...{ lat: 34.6543174745997, lng: 36.717622369264895 } });
    setZoom(10);
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-between h-[50px]  rounded-t">
              <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                Pick a location
              </div>
              <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
            </div>
            {/* {body} */}
            <div className="relative p-6 w-full ">
              <div className="w-[100%] text-[14px] font-[500]">
                {/* <button onClick={handleResetLocation}>Reset Location</button> */}

                <MapPicker
                  defaultLocation={defaultLocation}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: "500px"}}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  apiKey="AIzaSyDT73-9H6uA4yVJiNcn6uIN_WERoNmb-6Y"
                />
              </div>
            </div>

            {/*footer*/}
            <div className="flex items-center justify-end p-3 rounded-b">
              <div className=" flex pb-[10px] ">
                <div
                  onClick={() => setShowModal(false)}
                  className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    setValues({
                      ...values,
                      Location: JSON.stringify(location),
                    });
                    setShowModal(false)
                  }}
                  className="rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default MapModel;

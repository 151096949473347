import React from "react";

export default function FormRow({label, inputType, values, setValues, changeHandler, name}) {
  return (
    <div className="mb-2">
      <label className="block uppercase text-end tracking-wide text-gray-700 text-xs font-arabicMedium mb-2">
        {label}
      </label>
      <input
        type={inputType}
        value={values[name]}
        onChange={changeHandler}
        name={name}
        className="appearance-none rounded-full text-end font-arabicLight block w-full bg-gray-200 text-gray-700 border   py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
      />
    </div>
  );
}

import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import FileItem from "../FileUploader/FileItem";
import Loading from "../Loading";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function UploadModal({ setShowModal }) {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sheetFile, setSheetFile] = useState([]);
  const [error, setError] = useState(null);
  const removeSheetFile = (filename, index) => {
    setSheetFile(null);
  };

  const uploadSheet = async (e) => {
    e.preventDefault()
    if (sheetFile.length !== 0) {
      setError(null);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", sheetFile[0], sheetFile[0].name);
      axios
        .post(
          `${"https://apisedi.sedihisham.com"}/excel-file/upload/products`,
          formData
        )
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Your changes had been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
        });
    } else {
      setError("Please set an excel sheet first.");
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[30%]">
          <form onSubmit={uploadSheet}>
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col jusitify-between">
              <div className="ml-4 mt-2 font-[300] text-[15px]">
                Upload an excel file containing the updated products:
              </div>
              <div className="ml-[160px] mb-2">
                <div className=" w-[130px] h-[30px] mt-6">
                  <FileUpload
                    required={true}
                    files={sheetFile}
                    setFiles={setSheetFile}
                    removeFile={removeSheetFile}
                    type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
                <FileItem
                  type="excel"
                  file={sheetFile?.[0]}
                  removeFile={removeSheetFile}
                  index="0"
                />
              </div>
              <div className="flex justify-evenly">
                <div
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="cursor-pointer rounded text-[#fff] font-[300] my-4 px-3 py-2 bg-[#0388CC]"
                >
                  Cancel
                </div>
                <input
                    type="submit"
                    className="cursor-pointer rounded text-[#fff] font-[300] my-4 px-3 py-2 bg-[#ff3333]"
                    value="Upload"
                  />
              </div>
              {error ? (
                <div className="text-[14px] ml-5 mb-2 text-[red]">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                </div>
              ) : null}
              {loading ? <Loading /> : null}
              {showError ? <NetworkError setShowModal={setShowError} /> : null}
            </div>
          </div></form>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default UploadModal;

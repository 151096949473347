import React, { useState } from "react";
import styles from "../../styles/settingsTabs.module.css";

function Manufacturers({manufacturers, setManufacturers}) {
  return (
    <div key={55} className={`${styles.tab} flex items-center`}>
      <input
        value="55"
        type="radio"
        name="settings-tabs"
        id="55"
        className={`${styles.tabSwicth}`}
      />
      <label htmlFor="55" id="55" className={`${styles.tabLabel}`}>
        <span className={`${styles.tabSpan} font-[400] text-[14px]`}>Manufacturers</span>
      </label>
      <div className={`${styles.tabContent}`}>
        <span>
          <div>
            <p className="text-[#000] text-[14px] font-[400]">
              Select Manufacturers of product:
            </p>
            <input
              className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Enter the product manufacturers"
              value={manufacturers}
              onChange={(e) => setManufacturers(e.target.value)}
              type="text"
            />
          </div>
        </span>
      </div>
    </div>
  );
}

export default Manufacturers;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// import AuthService from "../services.js/auth.service";
// import { useSelector } from "react-redux";

// export const login = createAsyncThunk(
//   "auth/login",
//   async ({ email, password }, thunkAPI) => {
//     try {
//       const data = await AuthService.login(email, password);
//       if (data === undefined) {
//         throw "e";
//       } else {
//         return { user: data };
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   await AuthService.logout();
// });

// const initialState = {
//   isLoggedIn: false,
//   user: null,
// };
// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   extraReducers: {
//     [login.fulfilled]: (state, action) => {
//       state.isLoggedIn = true;
//       state.user = action.payload.user;
//     },
//     [login.rejected]: (state, action) => {
//       state.isLoggedIn = false;
//       state.user = null;
//     },
//     [logout.fulfilled]: (state, action) => {
//       state.isLoggedIn = false;
//       state.user = null;
//     },
//   },
// });

// export const selectUser = (state) => state.auth;

// export const authReducer = authSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services.js/auth.service";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      if (data === undefined) {
        throw "e";
      } else {
        return { user: data };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = {
  isLoggedIn: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      });
  },
});

export const selectUser = (state) => state.auth;

export const authReducer = authSlice.reducer;

import React, { useEffect, useState } from "react";
import { exitModal } from "../assets/svgIcons";
import "./style.css";

const PopUp = ({ title, open,  setOpen, children , onClose}) => {



  useEffect(() => {
    if (!open) {
      if (onClose) {
        onClose(false);
      }
    }
    console.log("oppo")
  }, [open]);


  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [open]);

  if (!open) return null;

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="scroll">



      
      <div
        className={`modalOverlay ${open ? "open" : ""}`}
        onClick={closeModal}
      ></div>







      <div className={`modalContainer ${open ? "open" : ""}`}>
        <div className="modalHeader">
          <span className="modalTitle">{title}</span>
          <span className="exitModal" onClick={closeModal}>
            {exitModal}
          </span>
        </div>
        <div className="modalChildren">{children}</div>
      </div>
    </div>
  );
};

export default PopUp;

import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

function OfferStatus({ status, offerId, disabled }) {
  const [active, setActive] = useState(status);
  const changeState = () => {
    if (!disabled) {
      active
        ? axios
            .get(
              `${"https://apisedi.sedihisham.com"}/offers/updatestate/${offerId}/desactive`
            )
            .then(setActive(false))
        : axios
            .get(
              `${"https://apisedi.sedihisham.com"}/offers/updatestate/${offerId}/active`
            )
            .then(setActive(true));
    }
  };
  return (
    <div
      onClick={changeState}
      className="w-[100%] cursor-pointer font-[400] flex items-center text-[13px] h-[35px] mr-2 rounded "
    >
      <div className={active ? "text-[green] mr-2" : "text-[red] mr-2"}>
        {active ? "Active" : "Pending"}
      </div>{" "}
      <div>
        <FontAwesomeIcon icon={faSort} />
      </div>
    </div>
  );
}

export default OfferStatus;

import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import MapModel from "./MapModel";

function NewContact({
  setShowModal,
  changeHandler,
  values,
  setValues,
  addContact,
  error,
}) {
  const [showMap, setShowMap] = useState(false);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={addContact}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Contact
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative p-6 w-full ">
                <div className="w-[95%]">
                  <div className="ml-[6%] w-[85%]">
                    <div>
                      <div className="mt-[10px] flex justify-between w-full">
                        <div className="w-[250px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="branch-name"
                          >
                            Name Of Branch{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter branch name"
                            onChange={changeHandler}
                            value={values.name}
                            name="name"
                            type="text"
                            id="branch-name"
                          />
                        </div>
                        <div className="w-[400px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="branch-details"
                          >
                            Branch Details{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter branch details"
                            onChange={changeHandler}
                            value={values.description}
                            name="description"
                            type="text"
                            id="branch-details"
                          />
                        </div>
                        <div className="w-[250px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="location"
                          >
                            Select your Location on Map{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <div className="flex">
                            <input
                              required
                              className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                              placeholder="Select branch location"
                              onChange={changeHandler}
                              value={values.Location}
                              name="Location"
                              type="text"
                              id="location"
                            />
                            <div
                              onClick={() => setShowMap(true)}
                              className="h-[30px] ml-[3px] rounded w-[35px] leading- text-[14px] text-center text-[#fff] bg-[#0388CC] flex items-center justify-center cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faLocationDot} />
                            </div>
                          </div>
                        </div>
                        {/* <div className="w-[200px]">
                        <label
                          className="text-[14px] font-[400]"
                          htmlFor="delivery-time"
                        >
                          Additional Fees{" "}
                          <span className="text-[red] font-[700]">*</span>
                        </label>
                        <input
                        required
                          className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                          placeholder="Enter delivery time"
                          onChange={(e)=>{setValues({...values, additional_fees: parseInt(e.target.value)})}}
                          value={values.additional_fees}
                          name="additional_fees"
                          type="number"
                          min="0"
                          id="branch-name"
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="mt-[18px]">
                      <p className="text-[#000] font-[700] text-[13px]">
                        Details
                      </p>
                      <div className="flex justify-between w-full">
                        <div className="w-[200px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="opening-times"
                          >
                            Openings Times{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter opening times"
                            onChange={changeHandler}
                            value={values.openings_time}
                            name="openings_time"
                            type="text"
                            id="opening-times"
                          />
                        </div>
                        <div className="w-[200px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="phone"
                          >
                            Telephone{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter phone number"
                            onChange={changeHandler}
                            value={values.phone_number}
                            name="phone_number"
                            type="tel"
                            id="phone"
                          />
                        </div>
                        <div className="w-[200px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="fax"
                          >
                            Fax <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter fax"
                            onChange={changeHandler}
                            value={values.fax}
                            name="fax"
                            type="text"
                            id="fax"
                          />
                        </div>
                        <div className="w-[200px]">
                          <label
                            className="text-[14px] font-[400]"
                            htmlFor="delivery-time"
                          >
                            For the delivery timing{" "}
                            <span className="text-[red] font-[700]">*</span>
                          </label>
                          <input
                            required
                            className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                            placeholder="Enter delivery time"
                            onChange={changeHandler}
                            value={values.delivery_time}
                            name="delivery_time"
                            type="text"
                            id="branch-name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {error ? (
                <p className="text-[14px] ml-4 font-[400] mt-2 text-[red]">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                </p>
              ) : null}
              {showMap && (
                <MapModel
                  setShowModal={setShowMap}
                  setValues={setValues}
                  values={values}
                />
              )}
              {/*footer*/}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    required
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewContact;

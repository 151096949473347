import React from 'react'
import LoginForm from '../components/LoginForm'

export default function Login() {
  return (
    <div className="flex font-arabic  mx-[5%] w-[90%] mt-32  m-5">
      <div className="flex-[50%] mt-10 justify-center items-center  flex-col mx-8 ">
        <p className="text-red-600 animate-bounce tracking-wide text-4xl text-center font-arabic font-[700]">
          أهلا بك !
        </p>
        {/* <p className="my-5 text-xl tracking-tight text-center text-gray-600 font-arabicMedium">
          لأول مرة ، يجب عليك تسجيل الدخول أو التسجيل
        </p> */}
        <div className="flex items-center justify-center mt-12">
          <img src="./images/logo.png" width={200} height={100} />
        </div>
      </div>
      <div className="flex-[50%] mt-6 w-full">
        <LoginForm />
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit";

export const itemSlice = createSlice({
  name: "items",
  initialState: {
    category:"",
    product:null,
    cooksCategory:null,
    search:"",
  },
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setProduct: (state, action) =>{
        state.product = action.payload;
    },
    setCooksCategory: (state, action) =>{
        state.cooksCategory = action.payload;
    },
    setSearch: (state, action) =>{
        state.search = action.payload;
    },
  },
});

export const {
  setCategory,
  setProduct,
  setCooksCategory,
  setSearch
} = itemSlice.actions;

export const selectCategory = (state) => state.item?.category;
export const selectProduct = (state) => state.item?.product
export const selectCooksCategory = (state) => state.item?.cooksCategory
export const selectSearch = (state) => state.item?.search

export  const itemReducer = itemSlice.reducer;

import React from "react";
import {
  HomePage,
  ProductsPage,
  ContactUsPage,
  WhoWeArePage,
  PrivacyPolicy,
  Careers,
} from "./PagesTabs";
import { AdminUsers, Customers } from "./UsersTabs";
import { Activities, News } from "./NewsTabs";
import { Offers, Coupons } from "./OffersTabs";
import { JobApplications, JobsManagement } from "./CareersTabs";
import { Preferences, GeneralSettings, ShipmentFee, Currencies } from "./SettingsTabs";
import {
  SubSubCategories,
  SubCategories,
  EditCategory,
  ProductsByCategory,
} from "./CategoryDetailsTabs";
import { EditCooksCategory, CooksByCategory } from "./CooksCategoryDetailsTabs";
import {
  StockInventory,
  Features,
  EditProduct,
  Settings,
} from "./ProductDetailsTabs";
import styles from "../styles/tabs.module.css";
import { Link } from "react-router-dom";
import JopsManagment from "../pages/JopsManagment";

function Tab({
  title,
  value,
  handleCheck,
  labelId,
  selectedTab,
  content,
  openModal,
  children,
  url
}) {
  const tabs = {
    HomePage: <HomePage />,
    ProductsPage: <ProductsPage />,
    ContactUsPage: <ContactUsPage />,
    WhoWeArePage: <WhoWeArePage />,
    PrivacyPolicy: <PrivacyPolicy />,
    Careers: <Careers />,
    AdminUsers: <AdminUsers />,
    Customers: <Customers />,
    Activities: <Activities />,
    News: <News />,
    Offers: <Offers />,
    Coupons: <Coupons />,
    JobsManagement: <JopsManagment />,
    JobApplications: <JobApplications />,
    Preferences: <Preferences />,
    GeneralSettings: <GeneralSettings />,
    ShipmentFee: <ShipmentFee />,
    Currencies: <Currencies />,
    ProductsByCategory: <ProductsByCategory />,
    SubCategories: <SubCategories />,
    SubSubCategories: <SubSubCategories />,
    EditCategory: <EditCategory />,
    EditCooksCategory: <EditCooksCategory />,
    CooksByCategory: <CooksByCategory />,
    Settings: <Settings />,
    Features: <Features />,
    StockInventory: <StockInventory />,
    EditProduct: <EditProduct />,
  };

  return (
    <div key={selectedTab} className={`${styles.tab}`}>
      <input
        onChange={(e) => {
          handleCheck(e);
        }}
        value={value}
        type="radio"
        name="css-tabs"
        id={value}
        defaultChecked={selectedTab === value}
        className={`${styles.tabSwicth}`}
      />
      <label
        htmlFor={value}
        id={labelId}
        className={`${styles.tabLabel} ${styles[labelId]}`}
      >
        <Link to={url}>
          <span className={`${styles.tabSpan} font-[400] text-[14px]`}>
            {title}
          </span>
        </Link>
      </label>
      {/* <div className={` `}> */}
        <div
          className={`${styles.tabContent} scrollbar-thin scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full`}
        >
          <span>{tabs[content]}</span>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Tab;

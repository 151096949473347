import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewPolicy from "../Modals/NewPolicy";
import {
  faCirclePlus,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from "sweetalert2";
function PrivacyPolicy() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const [showModal, setShowModal] = useState(false);

  const fetchPolicies = async () => {
    const response = await axios.get(
      `${"https://apisedi.sedihisham.com"}/pages/getall/privacy_policy`
    );
    const data = response.data;
    data.map((policy) => {
      if (policy.field_name === "general") {
        setGeneralDescAr(policy?.description_ar);
        setGeneralDescEn(policy?.description_en);
      } else if (policy?.field_name === "privacy") {
        setPoliticDescAr(policy?.description_ar);
        setPoliticDescEn(policy?.description_en);
      } else if (policy?.field_name === "financial") {
        setFinancialDescAr(policy?.description_ar);
        setFinancialDescEn(policy?.description_en);
      }
    });
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const [generalDescAr, setGeneralDescAr] = useState(false);
  const [generalDescEn, setGeneralDescEn] = useState(false);

  const [politicDescAr, setPoliticDescAr] = useState(false);
  const [politicDescEn, setPoliticDescEn] = useState(false);

  const [financialDescAr, setFinancialDescAr] = useState(false);
  const [financialDescEn, setFinancialDescEn] = useState(false);

  const submitPolicies = async () => {
    setLoading(true);
    await axios
      .post(`${"https://apisedi.sedihisham.com"}/pages/privacy_policy/create`, {
        sections: [
          {
            field_name: "general",
            description_ar: generalDescAr !== false ? generalDescAr : " ",
            description_en: generalDescEn !== false ? generalDescEn : " ",
          },
          {
            field_name: "privacy",
            description_ar: politicDescAr !== false ? politicDescAr : " ",
            description_en: politicDescEn !== false ? politicDescEn : " ",
          },
          {
            field_name: "financial",
            description_ar: financialDescAr !== false ? financialDescAr : " ",
            description_en: financialDescEn !== false ? financialDescEn : " ",
          },
        ],
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <div>
      <div>
        <PolicyForm
          descAr={generalDescAr}
          descEn={generalDescEn}
          setDescAr={setGeneralDescAr}
          setDescEn={setGeneralDescEn}
          title="شروط عامة"
          index={1}
        />
        <PolicyForm
          descAr={politicDescAr}
          descEn={politicDescEn}
          setDescAr={setPoliticDescAr}
          setDescEn={setPoliticDescEn}
          title="سياسة الخصوصية"
          index={2}
        />
        <PolicyForm
          descAr={financialDescAr}
          descEn={financialDescEn}
          setDescAr={setFinancialDescAr}
          setDescEn={setFinancialDescEn}
          title="الشروط المالية"
          index={3}
        />
      </div>
      {loading && <Loading />}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="flex pb-[10px] float-right mr-[80px] mt-[20px]">
        <div className="cursor-pointer rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
          Cancel
        </div>
        <div
          onClick={submitPolicies}
          className="cursor-pointer rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

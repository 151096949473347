const style = {
  border: "none",
  color: "#033362",
  fontSize: "14px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "gender",
    header: <h3 style={{ fontWeight: "700" }}>Gender</h3>,
    defaultFlex: 1.2,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "name",
    header: <h3 style={{ fontWeight: "700" }}>Name</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "email",
    header: <h3 style={{ fontWeight: "700" }}>Email</h3>,
    defaultFlex: 3,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "phone",
    header: <h3 style={{ fontWeight: "700" }}>Phone Number</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"text-[14px] w-fit px-[5px] font-[400] rounded text-[#0388CC]"
  },
  {
    name: "points",
    header: <h3 style={{ fontWeight: "700" }}>Points</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "level",
    header: <h3 style={{ fontWeight: "700" }}>Level</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "city",
    header: <h3 style={{ fontWeight: "700" }}>City</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "registred",
    header: <h3 style={{ fontWeight: "700" }}>Registred</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "lastSeen",
    header: <h3 style={{ fontWeight: "700" }}>Last Seen</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.7,
    headerProps: { style: style },
  },
];

export default columns;
